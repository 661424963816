:root {
    --star-color: #ccc;
    --star-background: #2f80cb;
}

html,
body {
    scroll-behavior: smooth;
    scroll-padding-top: 170px;
}

a {
    cursor: pointer;
}

.groe_aus_feat {
    display: block !important;
    font-size: 13px !important;
    line-height: 190%;
    margin-left: 67px;
}

.artikel_basket-banner-red {
    display: table;
    width: 100%;
    margin-top: 15px;
}

.artikel_basket-banner-red li {
    background: #e05143;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    vertical-align: middle;
    display: table-cell;
    padding: 12px;
    font-size: 32px;
    color: #fff;
    font-weight: 300;
}

.artikel_basket-banner-red span {
    color: #fff;
    float: right;
    text-align: right;
    margin-top: 4px;
    font-size: 15px;
    font-weight: 300;
}

li.vorteil.gr_1x1 figure {
    background-size: 43%;
    height: 229px;
}

li.vorteil.gr_1x1 div {
    height: 80px;
}

.artikel_badge {
    position: absolute;
    z-index: 10;
    left: 0;
    padding: 13px 10px;
    background: $rot;
    border-radius: 5px;
    color: #fff;
    top: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.wkBox {
    min-width: 116px;
}

.free-article {
    font-weight: 700;
    font-size: 14px;
    text-align: right;
    color: #2f80cb;
    margin: 20px 0 0 0;
}

img.wk_pen {
    display: none;
}

.wk_counter .wkBox,
.wk_pic .wkBox {
    min-width: 0;
    justify-content: center;
}

figure.button#nurDerLadePlatzhalter {
    float: right;
    clear: both;
    height: 68px;
    width: 236px;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 34px;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 100%;
    background: #999;
    box-sizing: border-box;
    padding: 20px 30px;
    text-align: center;
}

figure.button#nurDerLadePlatzhalter:after {
    content: "";
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-top-color: #fff;

    @include browserPrefixes(animation, spin 800ms infinite linear, webkit);

    display: block;
    position: absolute;
    top: 25px;
    right: 11px;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.se .herstellervideo .button {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 300;
}

.box.free-Shipping {
    line-height: 120%;
    font-size: 15px;
}

ul.product_additions {
    display: flex;
}

.product_additions li {
    width: 110px;
    height: 110px;
    background: #fff;
    border-radius: 5px;
    box-shadow: $schattenU;
    margin: 8px 16px 6px 0;
    padding: 11px;
    box-sizing: border-box;
    text-align: center;
}

.product_additions li img {
    width: 68px;
    height: 68px;
    margin: 0 0 5px 0;
}

iframe[name^="google_conversion_frame"] {
    height: 0 !important;
    display: block;
}

.shop_mitte td[width="5"] {
    display: none;
}

#shopLayer[data-pp="wk"] {
    flex-direction: column;
    display: flex;
}

#shopLayer[data-pp="wk"] .zahlung-versand {
    pointer-events: none;
    cursor: default;
}

#shopLayer[data-pp="suche"] #blaettern_oben {
    display: none;
}

#shopLayer[data-pp="suche"] .tab_k {
    padding: 30px 0px 20px 10px;
}

table[style*="#CC0000"] {
    border: 2px solid $rot !important;
    border-radius: 5px;
    margin: 30px auto;
    padding: 20px;
    box-shadow: $schattenU2;
}

span.dpdexptel,
span.dpdexptel u a {
    font-size: 17px;
    font-weight: 400;
}

p strong {
    font-weight: 800;
}

.box {
    background: white;
    padding: 16px;
    border-radius: 5px;
    box-shadow: $schattenU;
    margin-top: 16px;
}

span span:empty {
    display: none !important;
}

.box.vid {
    padding: 20px 20px 16px 20px;
}

.box.vid video {
    width: 100%;
}

#lexikon_suche_ergebnis ul li {
    display: inline-block;
    padding: 5px;
    font-size: 17px;
}

span.gloss_ajax_text {
    font-size: 17px;
    color: $blau;
}

span.gloss_ajax_text:hover {
    color: $schriftfarbe;
}

ul.men_aug2:empty {
    display: none !important;
}

.headerAusSicht.scrolltNachUnten #shopHeader {
    margin-top: -500px;
}

.headerAusSicht.scrolltNachOben #shopHeader {
    margin-top: 0;
}

#shopHeader.maxShadow {
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.6);
}

#shopHeader > nav section .active .alvl_1,
#shopHeader > nav section li:hover > .alvl_1 {
    color: $blau;
}

#shopHeader > nav .men_zk1_22 a {
    color: RGB(231, 76, 60);
}

#shopHeader > nav li.hover div {
    left: 0;
}

#shopLayer[data-pp="aa"] #shopHeader {
    position: relative;
}

#shopLayer[data-pp="aa"] {
    padding-top: 0;
}

#schnellog_za_table button {
    padding: 0 60px;
}

#schnellog_za_table span.ssl_t {
    margin-top: 0;
}

li.pdf-gutschein img {
    width: 200px;
}

[data-pp="perart"] .se {
    background: none !important;
}

#k_suche_etc a[data-num] {
    width: 80px;
}

#k_suche_etc a[data-num]:before {
    line-height: 19px;
    font-size: 11px;
    color: white;
    content: attr(data-num);
    display: block;
    position: absolute;
    margin-left: 49px;
    top: 18px;
    border-radius: 20px;
    min-width: 19px;
    min-height: 19px;
    text-align: center;
    box-sizing: border-box;
}

#k_suche_etc a[data-num="0"]:before,
#k_wk[data-num=""]:before {
    display: none;
    opacity: 0;
}

#k_wk.waiting {
    background-image: url(#{$cdnPfad}/pre_3.gif);
    background-size: 50px 50px;
}

.sucheTexte {
    font-size: inherit;
}

.shBagInh ul li {
    transition: background 0.4s;
    padding: 16px 0 22px 95px;
    background: no-repeat 15px 50%;
    background-size: 60px auto;
    border-bottom: 1px solid #e8e8e8;
}

*[data-num="1"] > .shBagInh ul li:hover {
    background-color: #fff;
}

.shBagInh ul li:hover {
    background-color: #e8e8e8;
}

.shBagInh ul li b {
    font-size: 16px;
    height: 12px;
    display: block;
    padding-bottom: 10px;
    line-height: 12px;
}

.shBagInh ul li b div {
    display: inline;
    font-size: 11px;
    color: #9e9e9e;
}

.shBagInh ul li div.WkartNr {
    height: 12px;
    display: block;
    padding-bottom: 10px;
    font-size: 11px;
    color: #9e9e9e;
}

.shBagInh ul li div.wkSpez {
    height: 12px;
}

.shBagInh ul li div.wkSpez i {
    font-size: 11px;
    color: #9e9e9e;
    font-style: normal;
}

.shBagInh span[data-inh=""] {
    display: none;
}

div.hover > .shBagInh {
    display: block;
}

#k_merk .shBagInh ul li b {
    padding: 0;
    line-height: normal;
    display: table-cell;
    width: 279px;
    vertical-align: middle;
    height: 61px;
}

#k_merk .shBagInh ul li {
    display: block;
}

#k_account .shBagInh #s_l_pass.active + #pwvergessen {
    margin-top: -54px;
}

#suchf.action ul li button {
    background-image: url(#{$cdnPfad}/search-hover.svg);
}

#suchf.action ul li input {
    color: $blau;
}

#grosserLayer.hidden {
    height: 0 !important;
    overflow: hidden;
}

#grosserLayer {
    position: absolute;
    top: 140px;
    background: RGBA(47, 128, 203, 0.97) 50% no-repeat;
    transition: height 0.5s;
    overflow: auto;
}

#grosserLayer.loading {
    background-image: url(#{$cdnPfad}/pre_3.gif);
}

#cookieMeldung a:first-child {
    display: none;
}

.location_headline {
    position: absolute;
    height: 103px;
    line-height: 103px;
}

#shopFooter #k_welt {
    h2 {
        font-size: 26px;
        margin-bottom: 10px;
    }

    a:hover span {
        color: $blau !important;
    }

    ul {
        display: grid;
        grid-template-columns: minmax(250px, max-content) repeat(auto-fill, 310px);
        gap: 16px 48px;
        justify-content: center;

        li {
            border-radius: 5px;
            padding: 15px 20px;

            &:not(.headline):hover {
                background: $hellgrau;
                cursor: pointer;
            }

            &.active {
                background: #fff;
                border: 2px solid #000;
            }

            &.headline {
                grid-column: 1 / -1;
            }

            a,
            div {
                display: flex;
                align-items: center;
                gap: 8px;

                span {
                    color: $agrau !important;
                    font-size: 16px !important;
                    white-space: nowrap;
                }

                div {
                    margin: 0 0 0 20px;

                    a {
                        font-size: 16px;
                        white-space: nowrap;

                        &:first-child:after {
                            content: "";
                            border-right: 2px solid $dgrau;
                            width: 1px;
                            height: 20px;
                            display: inline-block;
                        }
                        &.active,
                        &:hover {
                            color: $blau !important;
                        }
                    }
                }
            }

            img {
                width: 30px;
            }
        }
    }
}

@media (max-width: 760px) {
    #shopFooter {
        #k_welt {
            ul {
                grid-template-columns: 1fr !important;
                padding: 0 10px;

                li {
                    img {
                        width: 70px;
                    }

                    a,
                    div {
                        font-size: 43px !important;

                        span {
                            font-size: 43px !important;
                        }

                        gap: 16px;

                        div {
                            margin-left: auto;
                        }
                    }
                }
            }

            h2 {
                font-size: 43px;
            }
        }
    }

    #shopFooter #k_welt ul li div div a:first-child:after {
        content: "";
        border-right: 2px solid #aaaaaa;
        width: 1px;
        height: 40px;
        display: inline-block;
    }
}

input[type="radio"] {
    @include browserPrefixes(appearance, radio, webkit moz);
}

#grosserLayer {
    position: fixed;
    left: 0;
    z-index: 1000000;
    overflow-x: hidden !important;
}

.sucheGross {
    margin: 50px auto 0 auto;
    font-size: 20px;
    min-width: 756px;
    padding: 0 12px;
    box-sizing: border-box;
}

.sucheGross *,
.sucheGross {
    color: white;
}

.sucheGross:-webkit-input-placeholder {
    color: white;
}

.sucheGross:-moz-placeholder {
    color: white;
}

.sucheGross:-ms-input-placeholder {
    color: white;
}

.sucheGross input:-moz-placeholder {
    color: white;
}

.sucheGross .se.list_se.single {
    width: 506px;
}

.sucheGross .se.list_se.single > li {
    position: absolute;
}

.sucheGross .se.list_se.single > li:nth-child(2) {
    margin-left: 260px;
}

.sucheGross input {
    margin-top: 50px;
    display: block;
    width: 100% !important;
    font-size: 117px;
    height: 180px;
    background: none;
    border-bottom: 2px solid white;
    margin-bottom: 60px;
}

.sucheGross nav > div {
    float: left;
    width: 488px;
    text-align: center;
}

.sucheGross nav {
    width: 1469px;
    margin: 0 auto;
    display: block;
}

.sucheGross nav,
.login_d {
    @include clearfix;
}

.sucheGross h3 {
    margin-bottom: 25px;
    font-size: 19px;
}

#grSucheClose {
    width: 3%;
    height: 3%;
    float: right;
    cursor: pointer;
}

.sucheGross.active input {
    width: 74% !important;
    transition: width 500ms;
}

button.such-button {
    background: #fff;
    color: #297ece;
    border-radius: 5px;
    padding: 20px 120px;
    font-size: 30px;
    position: absolute;
    top: 110px;
    right: -50px;
    opacity: 0;
}

.sucheGross.active button.such-button {
    opacity: 1;
    right: 0;
    transition: all 500ms;
}

.sucheGross #suchf {
    width: 100%;
    position: relative;
}

#suche_topMarken a,
#suche_topKategorien a {
    float: left;
    display: block;
    width: 50%;
    margin-bottom: 50px;
    height: 70px;
}

#suche_topMarken a img,
#suche_topKategorien a img {
    max-height: 70px;
    display: block;
    margin: 0 auto;
}

#suche_topKategorien a img {
    max-height: 36px;
    margin-bottom: 15px;
    font-size: 12px;
}

#dd_sort {
    display: none;
}

.see_ueberschrift {
    font-weight: bold;
    height: 0;
}

#blaettern_oben .select_sortierung_se_top {
    display: none;
}

.see_li {
    height: 42px;
    line-height: 42px;
    overflow: hidden;
}

.see_li:hover,
.dd_list li:hover {
    background: #2d8ae0;
}

.see_multi_checkbox {
    visibility: hidden;
    float: right;
}

.see_li label:before {
    content: " ";
    width: 18px;
    height: 18px;
    background: white;
    border-radius: 2px;
    display: block;
    float: left;
    margin: 12px 10px 0 10px;
}

.see_li.active label:before {
    background: white url(#{$cdnPfad}/dd_check.svg) no-repeat 50% / 10px 10px;
}

.see_li label {
    cursor: pointer;
    margin-left: -16px;
}

.see_multi_label {
    width: auto;
}

.see_alle_dd ul .active div[class*="_kopf"] a,
*[id*="dd_sort"] .active div[class*="_kopf"] a {
    color: rgb(47, 128, 203);
}

.see_alle_dd ul .active div[class*="_kopf"]:after {
    background-image: url(#{$cdnPfad}/dropdown-arrow-active.svg);
    transform: rotate(180deg);
}

.see_alle_dd ul div[class*="_aktiv"] {
    background-image: url(#{$cdnPfad}/dd_back_a.png);
}

.see_alle_dd ul div[class*="_aktiv"] a {
    font-weight: normal;
}

.slimScrollDiv:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    z-index: 100;
}

.see_alle_dd > ul.active,
.active[id*="dd_sort"] {
    border: 1px solid #004c93;
}

.see_alle_dd > ul.active ul,
.active[id*="dd_sort"] ul {
    border-top: 1px solid #004c93;
}

.dd_se input {
    width: 190px !important;
    background-color: white;
    border-radius: 5px;
    border: 0;
    height: 26px;
    line-height: 26px;
    margin-top: 5px;
}

.slimScrollBar {
    margin-right: 4px;
    background: rgba(255, 255, 255, 1) !important;
}

.button:hover {
    background-color: rgb(41, 112, 179);
}

.se_banner.gr_1x1 .button {
    top: unset;
    transform: none;
}

.se_slider .flex-control-nav {
    margin-top: -135px;
    position: absolute;
    z-index: 3;
    text-align: center;
    color: white;
    width: inherit;
}

.se_slider .flex-control-nav li {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    cursor: pointer;
}

.se_slider .flex-control-nav li a {
    display: block;
    background: white;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    color: white;
    font-size: 0;
    transform: scale(1);
    transition: transform 0.3s;
}

.se_slider .flex-control-nav li a.flex-active,
.se_slider .flex-control-nav li a:hover {
    transform: scale(1.4);
}

.video_text {
    padding: 19px 17px 0;
}

img.nichtGeladen {
    width: 100%;
    height: 100%;
    display: inline-block;
    background: no-repeat 50% 50%;
}

.se_diff {
    background-color: RGB(231, 76, 60);
}

.se_banner.sb {
    line-height: 120%;
    font-weight: 300;
}

.se li:hover .se_beobachten {
    background-image: url(#{$cdnPfad}/watchlist-idle-h.svg);
    opacity: 1;
}

.miniSlNav {
    display: block;
    position: absolute;
    height: 22px;
    top: 233px;
    width: 13px;
    background: white url(#{$cdnPfad}/picture-navigation.svg) no-repeat 50%;
    background-size: 13px auto;
    left: 16px;
    opacity: 0;
    transition: opacity 0.3s;
}

li:hover .miniSlNav {
    opacity: 0.8;
}

.miniSlNav.next {
    left: 195px;
    background-image: url(#{$cdnPfad}/picture-navigation-r.svg);
}

.se_farben .lief_gr_farbe1_k.pre {
    transform: translate(-100%, 0);
}

.se_farben .lief_gr_farbe1_k.mehr {
    background: RGB(115, 121, 130) url(#{$cdnPfad}/farbbubbles-plus.svg) 50% 50% no-repeat;
    background-size: 8px;
    color: transparent;
    line-height: 12px;
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
}

.se_farben .lief_gr_farbe1_k[data-farbe="5"] {
    border: 1px solid #cecece;
    box-sizing: border-box;
}

.se_farben .lief_gr_farbe1_k[style*="#FFFFFF"] {
    border: 1px solid #ccc;
}

.se_farben .lief_gr_farbe1_k.hidden {
    display: none;
}

.se_farben.slider .lief_gr_farbe1_k {
    position: absolute;
    transition:
        left 0.3s,
        transform 0.3s;
    left: 0;
}

.se_farben.slider .lief_gr_farbe1_k[data-i="0"] {
    left: 0;
}

.se_farben.slider .lief_gr_farbe1_k[data-i="1"] {
    left: 14.3%;
}

.se_farben.slider .lief_gr_farbe1_k[data-i="2"] {
    left: 28.6%;
}

.se_farben.slider .lief_gr_farbe1_k[data-i="3"] {
    left: 42.9%;
}

.se_farben.slider .lief_gr_farbe1_k[data-i="4"] {
    left: 57.2%;
}

.se_farben.slider .lief_gr_farbe1_k[data-i="5"] {
    left: 71.5%;
}

.se_farben.slider .lief_gr_farbe1_k[data-i="6"] {
    left: 85.8%;
}

.se_farben.slider .lief_gr_farbe1_k[data-i="6"] + .lief_gr_farbe1_k {
    left: 100%;
}

.se_farben.slider {
    position: relative;
}

.se_farben.slider .lief_gr_farbe1_k.outOfView {
    left: 110%;
}

.se_farben.slider .lief_gr_farbe1_k.outOfViewL {
    left: -14.3%;
}

.an_slider {
    position: relative;
}

.an_slider:after {
    height: inherit;
    width: 10px;
    position: absolute;
    content: " ";
    top: 0;
    right: 0;

    @include browserPrefixes(background, linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%));
}

.an_slider.hover:before {
    height: inherit;
    width: 10px;
    position: absolute;
    content: " ";
    top: 0;
    left: 0;

    @include browserPrefixes(background, linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%));
}

.se_farben {
    @include clearfix;
}

#aa_fullview_button {
    display: block;
    position: absolute;
    width: 26px;
    height: 26px;
    background: no-repeat 50% url(#{$cdnPfad}/picture-fullsize.svg);
    background-size: 26px;
    right: 0px;
    margin-top: -67px;
    cursor: pointer;
    z-index: 120;
    opacity: 0.7;
    padding: 20px;
}

body .se_unterteil * {
    color: $blau;
}

.se_vk.red {
    color: RGB(231, 76, 60);
    font-weight: bold;
}

.se_vk.red strike {
    color: RGB(158, 158, 158);
    text-decoration: line-through;
    font-size: 13px;
    display: inline-block;
}

.se > li:hover .azu_imgs img {
    opacity: 1;
}

.azu_imgs img[src*="girlsboys"] {
    width: 60%;
    height: auto;
}

.azu_imgs img[src*="performance"] {
    width: 50%;
    height: auto;
}

li.se_banner.gr_1x0-5.vorteil {
    pointer-events: none;
}

.vorteil figure {
    background: $dgrau no-repeat 50%;
    display: block;
    width: 100%;
    height: 50%;
}

#basket .xmas .vorteil figure,
ul.list_se .xmas .vorteil figure {
    background-color: #68b592 !important;
}

.vorteil.versand figure {
    background-image: url(#{$cdnPfad}/benefit-fast-delivery.svg);
}

.vorteil.umtausch figure {
    background-image: url(#{$cdnPfad}/benefit-exchange.svg);
}

.vorteil.zahlung figure {
    background-image: url(#{$cdnPfad}/benefit-payments-euro.svg);
}

.vorteil.free-versand figure {
    background-image: url(#{$cdnPfad}/benefit-free-delivery.svg);
}

.vorteil.competent-consulting figure {
    background-image: url(#{$cdnPfad}/benefit-competent-consulting.svg);
}

.vorteil.free-retoure figure {
    background-image: url(#{$cdnPfad}/benefit-free-retoure.svg);
}

.vorteil.save-shopping figure {
    background-image: url(#{$cdnPfad}/benefit-save-shopping.svg);
}

.vorteil.one-mio figure,
.benefit.one-mio figure {
    background-image: url(#{$cdnPfad}/b/one-mio_de-it.svg);
    background-size: auto;
}

.vorteil.artikel-25k figure,
.benefit.artikel-25k figure {
    background-image: url(#{$cdnPfad}/b/artikel_25k.svg);
    background-size: 85%;
}

.vorteil {
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    box-shadow: $schattenU;
}

.vorteil b,
.vorteil b a {
    font-weight: 700;
    display: block;
    font-size: inherit;
}

.vorteil > div {
    font-weight: normal;
    font-size: inherit;
    word-break: break-word;
    padding: 5px;
    display: table;
    height: 66px;
    width: 100%;
    box-sizing: border-box;

    @include browserPrefixes(hyphens, auto);
}

.vorteil div div {
    display: table-cell;
    font-size: inherit;
    vertical-align: middle;
    line-height: 130%;
}

.vorteil.x-mas-tree figure {
    background-image: url(#{$cdnPfad}/tannenbaum_w.svg);
    background-size: 22%;
    background-color: #346e50;
}

.vorteil.x-mas-gift figure {
    background-image: url(#{$cdnPfad}/gift.svg);
    background-size: 22%;
    background-color: #346e50;
}

[data-pp="aar"] .tab_k + table {
    background: #fff;
    border-radius: 5px;
    box-shadow: $schattenU;
    padding: 20px;
    max-width: 740px;
    margin: 30px auto;
}

[data-pp="aar"] .tab_k {
    height: 70px;
    max-width: 740px;
    margin: 0 auto;
}

[data-pp="aar"] .tab_k + table table table {
    width: 120px;
    float: right;
}

[data-pp="aar"] .tab_k + table table table img {
    width: 120px;
    height: auto;
    pointer-events: none;
}

form[name="ratt"] {
    width: 80%;
}

form[name="ratt"] table tr {
    height: 30px;
}

form[name="ratt"] input[name="titel"],
form[name="ratt"] textarea[name="text"] {
    width: 400px !important;
    padding: 4px;
}

form[name="ratt"] textarea[name="text"] {
    font-family: Roboto, Arial, sans-serif !important;
    font-size: 13px !important;
}

.cssBewSenden {
    background: $blau !important;
    border-radius: 5px !important;
    border: none !important;
    color: #fff;
}

#se_to_top {
    opacity: 0;
    position: absolute;
    background: url(#{$cdnPfad}/pg_top.png) no-repeat;
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
    margin-left: 731px;
    color: transparent;
}

#pacerGoOnButton {
    grid-column-end: span 7;
    display: flex;
    padding-top: 10px;
    margin: 0 auto;

    button {
        position: unset !important;
        width: auto !important;
    }
}

.se.list_se {
    &:after {
        display: none;
    }
}

.suche_erfolglos {
    grid-column-end: span 7;
    grid-row-end: span 3;
    padding: 20px;
    background: white;
    border-radius: 5px;
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin: 50px auto;

    p {
        font-size: 16px;
    }
}

#mehrAusSerieLink {
    width: 100%;
    margin: 16px 0 !important;
    padding: 0;
    text-align: center;
    background: #d9d9d9;
    color: #737982;
}

.tZoom {
    cursor: url(#{$cdnPfad}/b/ad_zoom_in.svg), auto !important;
}

#aa_pic_show.has-iframe .tZoom {
    cursor: initial !important;
}

#tZoomViewer {
    cursor: url(#{$cdnPfad}/b/ad_zoom_out.svg), auto !important;
}

.loading:before,
.loading-sm:before {
    top: 0;
    left: 0;
    display: block;
    height: inherit;
    width: inherit;
    background: no-repeat url(#{$cdnPfad}/pre_4.gif) 50% / 120px auto;
    border-radius: 5px;
    position: absolute;
    content: " ";
}

.loading-sm:before {
    background-size: 30px;
}

.Fullview figure .controls b.right {
    margin-right: -42px;
}

.Fullview figure .controls b.left {
    margin-left: -42px;
}

.aa_pic_ap {
    width: 700px;
}

.d15_vk.d15_lp {
    float: right;
    height: 34px;
    font-size: 28px;
    text-decoration: line-through;
    margin-right: 20px;
}

.small .d15_vk.d15_lp {
    font-size: 17px;
    text-decoration: line-through;
    margin-right: 10px;
    line-height: 47px;
}

section[data-anz="0"],
section[data-anz=""] {
    display: none;
}

table.aa_rating > tbody > tr:first-child,
table.aa_rating > tbody > tr:nth-child(2) {
    display: none;
}

.aa_rating_mehr {
    display: block;
    height: 38px;
    line-height: 38px;
    position: absolute;
    background: #d9d9d9;
    width: 100%;
    text-align: center;
    left: 0;
    bottom: 0;
}

span.aa_rating_typen:after {
    display: block;
    position: absolute;
    content: " ";
    border-top: 1px solid #d9d9d9;
    width: 100%;
    left: 0;
    margin-top: 18px;
}

table.aa_rating > tbody > tr:nth-last-child(3) span.aa_rating_typen:after {
    display: none;
}

.aar_top .aa_rating_snippet {
    display: block;
}

#aa_person img {
    width: 126px;
    height: 126px;
    border-radius: 300px;
    display: block;
    margin: 0 auto 20px auto;
    background: #d9d9d9;
}

*[data-keinegr="1"] {
    display: none;
}

#aa_video iframe {
    display: block;
    width: 100%;
    height: 325px;
}

.basketFlyer {
    opacity: 1;
    transform: scale(1);
    z-index: 1000;
    position: absolute;
    margin: 0;
    transition:
        left 1.5s,
        top 1.5s,
        opacity 1.5s,
        transform 1.5s,
        width 1.5s,
        height 1.5s;
}

figure.basketFlyer {
    height: 150px;
    width: 150px;
    background: white;
    border-radius: 5px;
    box-shadow: $schattenU;
    padding: 8px;
    box-sizing: border-box;
}

figure.basketFlyer img {
    height: 100%;
    width: 100%;
}

#cs_dark_matter {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 23000;
    top: 0;
}

#cs_layer {
    background: white;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 1024px;
    margin: 0 auto;
    overflow: hidden;
}

#cs_layer #cs_header {
    padding: 30px 0;
    box-shadow: $schattenU;
    text-align: center;
}

.hs-header-on-whishlist h1 {
    margin-bottom: 20px;
}

#cs_layer span {
    font-size: 13px;
    color: #9e9e9e;
    display: block;
    text-align: center;
}

#cs_header h1 {
    font-size: 28px;
    color: #737982;
    text-align: center;
}

#cs_header .button {
    width: 204px;
    text-align: center;
    box-sizing: border-box;
}

#cs_body {
    background: #f2f2f2;
    padding: 16px 16px 0 16px;
}

#cs_layer .button {
    font-size: 14px;
    margin: 30px 24px 0 24px;
    display: inline-block;
    text-transform: uppercase;
    &.disabled {
        background: #d9d9d9 !important;
        color: #737982;
    }
}

#weitersh {
    background: #d9d9d9 !important;
    color: #737982;
}

#weitersh:hover {
    background: #d0d0d0 !important;
}

#cs_layer .button:before {
    content: " ";
    display: block;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: 100000;
}

#cs_body > span {
    margin-bottom: 16px;
}

#cs_body .button {
    color: white;
}

#cs_body [data-bn] {
    height: 440px;
    width: 236px;
    float: left;
    overflow: hidden;
    background: white;
    border-radius: 5px;
    box-shadow: $schattenU;
    position: relative;
    box-sizing: border-box;
    margin: 0 4px 16px 4px;
    cursor: pointer;
}

#cs_body [data-bn]:nth-child(4n + 5) {
    margin-right: 0;
}

#cs_body {
    @include clearfix;
}

#cs_body [data-bn] .button {
    display: block;
    padding: 0;
    margin: 16px 0 0;
    position: absolute;
    width: 204px;
    left: 16px;
    bottom: 16px;
}

#cs_body [data-bn] .se_name {
    height: 48px;
    margin-top: 6px;
}

#cs_body [data-bn] figure {
    position: relative;
    height: 260px;
    overflow: hidden;
}

#cs_body [data-bn] figure img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 204px;
}

#cs_body [data-bn] .se_beobachten {
    position: absolute;
    right: 0px;
    z-index: 1;
}

#cs_body [data-bn] select {
    height: 30px;
    display: block;
    color: #a3abb8;
    line-height: 30px;
    font-size: 13px;
    width: 204px;
    background: #fff; // weil FireFox das sonst als gray darstellt
    position: absolute;
    top: 338px;
    left: 16px;
    border-bottom: 2px solid #d9d9d9;
}

ul.aktive_felder li {
    display: block;
    position: relative;
    width: 454px;
}

body #shopLayer ul.aktive_felder li input {
    width: 454px;
}

#ala_schnell {
    margin-right: 22px;
}

body #shopLayer ul.aktive_felder li input#schnellog_inpu5_2,
#shopLayer .div_login .aktive_felder label[for="schnellog_inpu5_2"].active:before {
    width: 145px;
}

.login_c input[type="radio"] + label {
    width: 387px;
    box-sizing: border-box;
}

body #shopLayer #schnellog.aktive_felder li input#schnellog_inpu5_1,
#shopLayer .div_login .aktive_felder label[for="schnellog_inpu5_1"].active:before {
    width: 295px;
}

body #shopLayer #schnellog.aktive_felder li input#schnellog_inpu5_2 {
    position: absolute;
    margin-left: 310px;
    top: -30px;
}

#shopLayer .div_login .aktive_felder label[for="schnellog_inpu5_2"] {
    left: 309px;
}

.ueb_zahlart,
.ueb_liefadr {
    color: #a3abb8;
    font-size: 14px;
    margin-right: 10px;
}

#shopMain .shop_mitte .mitte_login #schnellLog_feld12 select {
    display: inline-block;
    margin-top: 1px !important;
    padding: 4px;
}

ul.aktive_felder li label {
    position: absolute;
    top: -30px;
}

ul.aktive_felder {
    width: 470px;
}

#schnellog + table {
    width: 470px;
}

#schnellLog_feld12 {
    margin-top: 32px;
    text-align: left;
}

#schnellLog_feld12 select + span {
    padding-left: 10px;
    color: #a3abb8;
    font-size: 14px;
}

#schnellLog_feld8 {
    text-align: left;
}

.select_wrapper {
    margin-top: 30px;
    border-bottom: 2px solid $dgrau;
    margin-left: 14px;
    width: 454px;
}

img.wk_gutscheinmotiv {
    width: 84px;
}

span.error_vor_absendenbutton {
    display: inline-block;
    margin-top: 10px;
    height: auto;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid $rot;
    box-shadow: $schattenU;
}

span.b.versandart_auswahl_aktiv:after {
    content: "";
    width: 16px;
    height: 16px;
    background: RGB(47, 128, 203);
    border-radius: 16px;
    position: absolute;
    top: 20px;
    right: 20px;
}

span.b.versandart_auswahl:after {
    content: "";
    width: 16px;
    height: 16px;
    background: #ddd;
    border-radius: 16px;
    position: absolute;
    top: 20px;
    right: 20px;
}

span.b.versandart_auswahl_aktiv,
span.b.versandart_auswahl {
    padding: 5px 17px 4px 5px;
    display: block;
    background: #fff;
    margin: 4px 0;
    border-radius: 5px;
    width: 170px;
    font-size: 11px !important;
    line-height: 210%;
    position: relative;
    box-shadow: $schattenU;
}

span.b.versandart_auswahl_aktiv div,
span.b.versandart_auswahl div {
    font-size: 11px !important;
    font-weight: 400;
    line-height: 150%;
}

span.b.versandart_auswahl_aktiv img,
span.b.versandart_auswahl img {
    display: block;
    margin-bottom: 4px;
}

span.b.versandart_auswahl {
    opacity: 0.5;
}

.zk_versandarten span {
    display: none;
}

.zk_versandarten {
    position: absolute;
    left: -225px;
    top: 0;
}

.spalteReduziert strike + nobr {
    display: none;
}

.wk tr.spalteReduziert td:nth-last-child(1) .wkBox strike i span {
    text-align: right;
    text-decoration: line-through;
    color: #a3abb8;
    font-style: normal;
}

.wk tr.spalteReduziert td:nth-last-child(1) .wkBox strike i span * {
    display: none;
}

.wk tr.spalteReduziert td:nth-last-child(2) .wkBox:before,
.zk_wk_ep .wkBox:before,
.wk tr.spalteReduziert td:nth-last-child(1) .wkBox:before,
.zk_wk_ep .wkBox:before {
    top: 16px;
}

.wk_rab_zeile {
    text-decoration: line-through;
}

#pp_exp_btn {
    display: block;
    width: 236px;
    margin-right: 16px;
    margin-top: 16px;
}

.wk_zk_div {
    text-align: left !important;
    border: 0 !important;
    background: #e8e8e8;
    padding: 16px;
    margin: 0;
}

.wk_zk_div table {
    width: 100%;
}

td.zk_wk_gsum {
    .preis_in_wk,
    .gespreis_in_wk_strike {
        text-align: right;
    }
}

.zk_wk_gsum .wkBox {
    margin-right: 0;
    text-align: right;
}

.zk_wk_gsum .wkBox span.wk_mwst {
    text-align: right;
}

.zk_wk_summe {
    display: table;
    float: right;
    margin-top: 10px;
    margin-right: 5px;
}

.zk_wk_summe .b,
.guthaben {
    display: table-row;
}

.zk_wk_summe .b span,
.guthaben span {
    display: table-cell;
    color: #a3abb8;
}

.guthaben span {
    padding: 20px 0 0 0;
}

.zk_wk_summe .b span:first-child {
    padding-right: 24px;
}

.mitte_login.abschluss .button,
#wk .button {
    float: right;
    clear: both;
    height: 68px;
    width: 330px;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 34px;
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: initial;
}

.wk_zk_div,
.servicePro-Contra {
    @include clearfix;
}

.zk_wk_summe .b span:last-child,
.guthaben span:last-child {
    text-align: right;
}

.zk_gsum span {
    padding-top: 30px;
}

.zk_wk_summe span:last-child {
    font-size: 20px;
    color: #737982;
    padding-top: 6px;
}

.zk_gsum span:last-child {
    font-size: 24px;
    color: $blau;
}

.adress_tabelle {
    margin-top: 65px;
}

.adress_tabelle .wkBox {
    width: 236px;
    min-height: 144px;
    padding: 16px;
    margin-left: 16px;
    box-sizing: border-box;
    line-height: 20px;
}

.adress_tabelle .wkBox a {
    display: block;
    width: 26px;
    height: 26px;
    background: #a3abb8 50% 50% url(#{$cdnPfad}/edit.svg) no-repeat;
    border-radius: 100px;
    position: absolute;
    top: 16px;
    right: 16px;
}

.adress_tabelle * {
    color: #a2abb8;
}

.adress_tabelle th {
    padding-left: 16px;
    text-align: left;
    padding-bottom: 12px;
}

.adress_tabelle th,
.adress_tabelle th * {
    font-size: 20px;
    font-weight: bold;
}

.wkBox[data-za] {
    background: white 50% 40% no-repeat;
    font-size: 0;
}

.wkBox[data-za*="redit"] {
    background-image: url(#{$cdnPfad}/payment-creditcard-a.svg);
}

.wkBox[data-za*="PayPal"],
.wkBox[data-za*="Paypal"],
.wkBox[data-za*="paypal"] {
    background-image: url(#{$cdnPfad}/payment-paypal-a.svg);
}

.wkBox[data-za*="astschr"] {
    background-image: url(#{$cdnPfad}/payment-ec-bank-a.svg);
}

.wkBox[data-za*="orkasse"],
.wkBox[data-za*="orauskasse"] {
    background-image: url(#{$cdnPfad}/payment-vorkasse-a.svg);
}

.wkBox[data-za*="achnah"] {
    background-image: url(#{$cdnPfad}/payment-nachnahme-a.svg);
}

.wkBox[data-za*="echnung"] {
    background-image: url(#{$cdnPfad}/payment-rechnung-a.svg);
}

.wkBox[data-za*="aydirek"] {
    background-image: url(#{$cdnPfad}/paydirekt_brand.svg);
    background-size: 150px;
}

.wkBox[data-za*="mazon"] {
    background-image: url(#{$cdnPfad}/amazon_pay_brand.svg);
    background-size: 150px;
}

.wkBox.ala_box select {
    max-width: 100%;
}

#fnfcontainer {
    width: 740px;
    padding: 20px;
    background: white;
    border-radius: 5px;
    box-shadow: $schattenU;
    box-sizing: border-box;
    margin: 50px auto;

    .nicht-gefunden {
        display: flex;
        padding: 20px 40px;
        gap: 50px;
        align-items: center;

        > img {
            max-width: 200px;
        }

        .sorry {
            h1 {
                text-align: left;
                margin: 0 0 10px 0 !important;
            }

            > p {
                font-size: 22px;
            }

            > a {
                background: $blau;
                color: white;
                padding: 10px 30px;
                border-radius: 5px;
                display: inline-block;
                margin-top: 30px;
            }
        }
    }
}

#fnfcontainer h1 {
    font-weight: bold;
    font-size: 28px;
    text-align: center;
    margin: 30px;
}

.se.list_se > li.startbanner {
    background: $blau;
    padding: 5px 20px 20px 20px;
}

.se_slider sup {
    color: $blau;
}

.se.list_se > li.startbanner h1,
.se.list_se > li.startbanner p {
    color: #fff;
}

.startbanner .text-2-column {
    font-weight: lighter;
    column-count: 2;
    column-gap: 20px;
}

.serviceVideo {
    padding: 0;
}

.serviceVideo iframe {
    width: 100%;
}

.serviceVideo div {
    min-height: 30px;
    line-height: 30px;
    color: $blau;
    text-align: center;
}

#service_rechts > .serviceVideo:first-child {
    margin-top: 36px;
}

.servicePro-Contra > div {
    float: left;
    width: 236px;
}

.servicePro-Contra > div:first-child {
    margin-right: 16px;
}

.servicePro-Contra ul li {
    padding-left: 10px;
    margin-bottom: 8px;
    line-height: 18px;
}

.servicePro-Contra ul li:before {
    content: ".";
    width: 10px;
    height: 18px;
    line-height: 9px;
    margin-left: -10px;
    display: block;
    position: absolute;
}

#service_komplett_neben_menue {
    float: left;
    width: 992px;
}

.bestue_headline span {
    margin-left: 44px;
    color: inherit;
    font-size: inherit;
}

.bestue_headline {
    cursor: pointer;
}

* + .bestue_headline {
    margin-top: 8px;
}

#trustbadge {
    float: left;
    height: 90px;
    position: absolute;
    top: 0;
    left: 192px;
}

#trustbadge > div > div {
    border: 0 !important;
}

// Länder-Menü über Trustbadge
[id^="trustbadge-container"] {
    z-index: 100 !important;
}

#pgLoader {
    position: fixed;
    z-index: 100000000;
    background: RGBA(47, 128, 203, 0.9);
    font-size: 35px;
    display: table-cell;
    text-align: center;
    vertical-align: center;
    color: white;
    top: 0;
    width: 100%;
    height: 100%;
}

#pgLoader div {
    font-size: inherit;
    color: inherit;
}

#tipDiv {
    background: $blau;
    border-radius: 6px;
    padding: 16px;
    opacity: 0.97;
    z-index: 11001 !important;
}

#tipDiv * {
    color: white;
    line-height: 21px;
}

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
    outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}

.flexslider {
    margin: 0;
    padding: 0;
}

.flexslider .slides > li {
    display: none;
    backface-visibility: hidden;
}

.flexslider .slides img {
    width: 100%;
    display: block;
}

.flex-pauseplay span {
    text-transform: capitalize;
}

html[xmlns] .slides {
    display: block;
}

* html .slides {
    height: 1%;
}

span.error a {
    color: $blau;
}

span.error {
    border: 2px solid $rot;
    border-radius: 5px;
    padding: 20px 20px 20px 52px !important;
    color: $schriftfarbe !important;
    background: #fff;
    position: relative;
    margin: 30px auto;
    box-shadow: $schattenU;
    font-size: 16px;
    display: block;
}

span.error:before {
    content: " ";
    width: 52px;
    height: 100%;
    display: block;
    background: url(#{$cdnPfad}/icon_error.gif) 50% 50% no-repeat;
    position: absolute;
    top: 0;
    left: 0;
}

span.info {
    border: 2px solid RGB(42, 175, 186);
    border-radius: 5px;
    padding: 20px 20px 20px 52px !important;
    color: $schriftfarbe !important;
    background: #fff;
    position: relative;
    margin: 30px auto;
    box-shadow: $schattenU;
    font-size: 16px;
    display: block;
}

span.info:before {
    content: " ";
    width: 52px;
    height: 100%;
    display: block;
    background: url(#{$cdnPfad}/icon_info.gif) 50% 50% no-repeat;
    position: absolute;
    top: 0;
    left: 0;
}

table[style*="#DD2222"],
.errorWrapper {
    border: 1px solid red !important;
    padding: 18px;
    border-radius: 6px;
    background: coral;
    width: auto;
    margin: 6px auto;
}

table[style*="#DD2222"] *,
.errorWrapper * {
    color: $schriftfarbe;
    font-size: 16px;
}

img.achtung {
    height: 45px;
    margin-right: 20px;
}

nav .grafisch .men_her li a {
    font-size: 0 !important;
    width: 216px !important;
}

.hersteller_banner img {
    width: 100%;
    height: 100%;
}

.se .se_banner.gr_1x1.se_slider {
    display: flex;
    justify-content: center;
    height: fit-content;
}

.gutschein {
    background: $blau;
    border-radius: 5px;
    max-width: 600px;
    margin: 30px auto 0 auto;
}

.gutschein.ok-banner {
    padding: 20px;
    margin: 10px auto;
}

.gutschein p {
    color: #fff;
    padding: 30px 30px 10px 30px;
    text-align: center;
    font-size: 16px;
    margin: 0 auto;
}

.gutschein span {
    color: #fff;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 20px;
    display: block;
    font-size: 30px;
}

.gutschein b,
.gutschein a {
    color: #fff;
    font-size: inherit;
}

.banner {
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.banner.text {
    padding: 20px;
}

.banner span {
    font-size: 3em;
    line-height: 145%;
}

.banner h2 {
    font-size: 18em;
    line-height: 65%;
}

.banner p {
    color: #fff;
    position: absolute;
    bottom: 95px;
    width: 100%;
    left: 0;
    font-size: 22px;
}

.banner p span {
    display: block;
    font-size: 5em;
    line-height: 105%;
}

.se span.button {
    color: #fff !important;
}

.banner span.button {
    font-family: roboto, arial, sans-serif;
    font-size: 16px;
    position: absolute;
    bottom: 20px;
    transform: translateX(-50%);
    font-weight: 300;
}

.banner.text.yellow-red {
    background: linear-gradient(45deg, rgba(238, 130, 130, 1) 0%, rgba(255, 245, 160, 1) 100%);
}

.banner.text span {
    font-size: 4em;
}

.banner.text p.cap {
    text-transform: uppercase;
}

.banner.text p {
    font-size: 40px;
    position: absolute;
    bottom: 20px;
}

.se_banner.keinePunkte ol {
    display: none;
}

.se > li.se_banner.sb {
    padding: 20px;
}

.se > li.se_banner.sb h2,
.se > li.se_banner.sb h1 {
    color: #297ece;
    font-size: 24px;
    font-weight: 300;
    line-height: 33px;
    text-align: left;
    margin-bottom: 20px;
    column-span: all;
    column-fill: auto;
}

.se > li.se_banner.sb img {
    max-width: 200px;
}

ul.se > li > img {
    width: 100%;
}

.se > li.se_banner.gr_1-5x1.sb h2,
.se > li.se_banner.gr_1-5x1.sb h1,
.se > li.se_banner.gr_1x1.sb h2,
.se > li.se_banner.gr_1x1.sb h1,
.se > li.se_banner.gr_2x1.sb h2,
.se > li.se_banner.gr_2x1.sb h1,
.se > li.se_banner.gr_3x1.sb h2,
.se > li.se_banner.gr_3x1.sb h1,
.se > li.se_banner.gr_2-5x1.sb h2,
.se > li.se_banner.gr_2-5x1.sb h1 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
}

.se > li.se_banner.sb h3,
.se > li.se_banner.sb h4,
.se > li.se_banner.sb h5,
.se > li.se_banner.sb h6,
.se > li.se_banner.sb h7,
.se > li.se_banner.sb h8,
.se > li.se_banner.sb h9 {
    color: $blau;
    font-size: 14px;
    font-weight: 400;
    line-height: 135%;
    margin-bottom: 10px;
}

.se > li.se_banner.sb .text-2-column {
    font-weight: lighter;
    column-count: 2;
    column-gap: 20px;
}

.se > li.se_banner.gr_2x2 .text-2-column {
    max-height: 500px;
    overflow: hidden;
}

.se > li.se_banner.sb p {
    line-height: 128%;
    font-weight: lighter;
    margin: 0 0 15px 0;
}

.se > li.se_banner.sb .infobox {
    background: #297ece;
    padding: 10px 10px 1px 10px;
    margin: -9px;
    margin-top: 0;
}

.se > li.se_banner.sb .infobox p,
.se > li.se_banner.sb .infobox h3 {
    color: #fff;
}

.se > li.se_banner.sb ul {
    margin: 0 0 20px 0;
    padding: 0 0 0 17px;
}

.se > li.se_banner.sb ul li {
    list-style-type: square;
    color: #297ece;
    padding: 0 0 4px 0;
}

.se > li.se_banner.sb h2.big,
.se > li.se_banner.sb h3.big,
.se > li.se_banner.sb h4.big,
.se > li.se_banner.sb h5.big,
.se > li.se_banner.sb h6.big,
.se > li.se_banner.sb h7.big,
.se > li.se_banner.sb h8.big,
.se > li.se_banner.sb h9.big {
    font-size: 24px;
    font-weight: 700;
}

.se > li.se_banner.sb h2.small,
.se > li.se_banner.sb h3.small,
.se > li.se_banner.sb h4.small,
.se > li.se_banner.sb h5.small,
.se > li.se_banner.sb h6.small,
.se > li.se_banner.sb h7.small,
.se > li.se_banner.sb h8.small,
.se > li.se_banner.sb h9.small {
    font-size: 14px;
}

.se > li.gr_1x1 .button {
    position: absolute;
    bottom: 26px;
    padding: 0 10px;
    width: 170px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
}

.gr_1x2.se_slider .slides img + div {
    padding: 19px 17px 13px;
}

.se.list_se > li.hersteller {
    background: #64645f;
    text-align: center;
    font: 0/0 a;
}

.se.list_se > li.hersteller:before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.se.list_se > li.hersteller img {
    display: inline-block;
    vertical-align: middle;
    font: 16px/1 Arial sans-serif;
    max-width: 350px;
    height: 80%;
    width: 80%;
}

.se.side img + div {
    padding: 20px;
}

.se.side h2 {
    font-size: 28px;
    font-weight: 300;
    height: 31px;
    line-height: 22px;
}

.se.side > span {
    color: RGB(158, 158, 158);
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
}

.se.side .button {
    float: right;
}

.se.side .button {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 300;
}

.se > li.se_banner.sb.tabbed h2 {
    margin-top: 20px;
}

.se > li.se_banner.sb.tabbed {
    padding: 0 !important;
}

.card {
    width: 101%;
}

.wk_zk_div.card {
    width: auto;
}

.navicon {
    font-size: 21.6px;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
}

.navdot {
    font-size: 12px;
}

.tab-wrapper {
    height: auto;
    border-radius: 6px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: #fff;
}

.tab:checked:nth-of-type(1) ~ .tab__copy:nth-of-type(1) {
    opacity: 1;
    transition: 0.4s all ease-in;
    position: relative;
    z-index: 5;
    transform: translateY(0px);
}

.tab:checked:nth-of-type(2) ~ .tab__copy:nth-of-type(2) {
    opacity: 1;
    transition: 0.4s all ease-in;
    position: relative;
    z-index: 5;
    transform: translateY(0px);
}

.tab:checked:nth-of-type(3) ~ .tab__copy:nth-of-type(3) {
    opacity: 1;
    transition: 0.4s all ease-in;
    position: relative;
    z-index: 5;
    transform: translateY(0px);
}

.tab:first-of-type:not(:last-of-type) + label {
    border-radius: 6px 0 0 0;
}

.tab:not(:first-of-type):not(:last-of-type) + label {
    border-radius: 0 0 0 0;
}

.tab:last-of-type:not(:first-of-type) + label {
    border-radius: 0 6px 0 0;
}

.tab:checked + label {
    background-color: #fff !important;
    box-shadow: none;
    cursor: default;
    color: $blau;
    border: 0 !important;
}

.tab:checked + label:hover {
    background-color: #fff;
    box-shadow: none;
}

.tab + label {
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: #ccc;
    flex-grow: 1;
    text-align: center;
    background-color: #fff !important;
    transition: 0.4s all ease;
    height: 40px;
    padding-top: 20px;
    box-shadow: 0px -3px 6px #d7d7d7 inset;
    font-family: Roboto, Arial, sans-serif;
    font-size: 15px;
    font-weight: normal;
    border: 0 !important;

    @include browserPrefixes(user-select, none);
}

input[type="radio"].tab + label:before,
input[type="radio"].tab:checked + label:before {
    content: "";
    box-shadow: none !important;
    border-radius: 0 !important;
    background: transparent !important;
}

.tab + label:hover {
    background-color: #f7f7f7;
    box-shadow: 0 -2px 3px #dddddd inset;
    transition: 0.4s all ease;
}

.tab__copy {
    padding: 20px;
    background-color: transparent;
    height: auto;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: -1;
    opacity: 1;
    transform: translateY(3px);
}

.slide-wrapper {
    height: 240px;
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 0px;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: center;
}

.slide {
    display: none;
    z-index: 1;
}

.slide:checked + label {
    color: rgba(0, 15, 30, 0.8);
    transition: 0.65s all ease;
}

.slide:checked + label:nth-of-type(1) ~ .slide__reel {
    left: 0%;
}

.slide:checked + label:nth-of-type(2) ~ .slide__reel {
    left: -100%;
}

.slide:checked + label:nth-of-type(3) ~ .slide__reel {
    left: -200%;
}

.slide:checked + label:nth-of-type(4) ~ .slide__reel {
    left: -300%;
}

.slide:checked + label:nth-of-type(5) ~ .slide__reel {
    left: -400%;
}

.slide:checked + label:nth-of-type(6) ~ .slide__reel {
    left: -500%;
}

.slide:checked + label:nth-of-type(7) ~ .slide__reel {
    left: -600%;
}

.slide:checked + label:hover {
    color: rgba(0, 15, 30, 0.8);
}

.slide + label {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.25);
    text-align: center;
    transition: 0.65s all ease;
    margin-left: 8px;
    margin-right: 8px;
    z-index: 1;
}

.slide + label:hover {
    color: rgba(0, 92, 183, 0.8);
    transition: 0.65s all ease;
}

.slide__reel {
    position: absolute;
    width: 700%;
    height: 100%;
    transition: left 0.65s;
    display: flex;
    flex-flow: row nowrap;
    z-index: 0;
}

.slide__content {
    margin-top: 10px;
    margin-bottom: 0px;
    width: auto;
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include browserPrefixes(user-select, none);
}

.contact_form {
    float: left;
    padding: 0;
    min-width: 100%;
    box-sizing: border-box;
    margin-right: 0;
}

.form_left {
    min-width: 250px;
}

.contact_form form br.clear {
    display: none;
}

.contact_form select {
    padding: 0;
    border-radius: 0;
    -webkit-appearance: none;
    color: $blau;
    background: white;
    border-bottom: 2px solid #ddd;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    display: block;
    width: 311px;
    margin: 32px 16px 0 16px;
    font-weight: 400;
    position: relative;
}

.contact_form #select + label:after {
    content: " ";
    background-image: url("#{$cdnPfad}/sprite.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: -20px;
    right: -5px;
    pointer-events: none;
    width: 26px;
    height: 26px;
    background-size: 400%;
}

.contact_form form {
    width: 100%;
    display: flex !important;
    gap: 32px;
    padding: 0 16px;
    box-sizing: border-box;
}

body #shopLayer .contact_form .aktive_felder label.label2 {
    position: relative;
    padding: 0;
    margin-top: 28px;
    display: block;
}

body #shopLayer .contact_form .button {
    position: relative;
    overflow: hidden;
    background: $blau;
    border-radius: 5px;
    color: white;
    height: 38px;
    line-height: 38px;
    padding: 0 32px;
    font-size: 13px;
    font-weight: 400;
    transition: background 0.3s;
    cursor: pointer;
    float: right;
    width: 193px;
}

.contact_form .form_left,
.contact_form .form_right {
    position: relative;
}

.contact_form .form_right {
    flex: 1 1;
}

body #shopLayer .contact_form .aktive_felder input {
    width: 311px;
}

body #shopLayer .aktive_felder label.active:before {
    width: 311px;
}

body #shopLayer .contact_form .aktive_felder textarea {
    margin: 0px;
    height: 186px;
    width: 100%;
    border: 2px solid #ddd;
    top: 60px;
    left: 17px;
    padding: 5px;
    box-sizing: border-box;
}

.clear {
    clear: both;
}

.contact_form select.active {
    border-bottom: 2px solid $blau;
}

.body #shopLayer .aktive_felder label .label2 {
    position: relative;
}

#service_mitte {
    .wrap-pflege {
        display: block !important;
    }
}

#landingpage .temp2 #service_mitte {
    width: 1008px;
    margin-right: 0 !important;
}

#landingpage .se_banner h2 {
    color: #297ece;
    font-size: 24px;
    font-weight: 300;
    line-height: 33px;
    text-align: left;
    margin-bottom: 20px;
}

#landingpage #service_wrapper #service_rechts > .serviceImage:first-child {
    margin: 0 0 16px 0;
}

#landingpage #service_wrapper #service_mitte .list_se {
    margin: 0 !important;
}

#service_rechts figure.serviceImage img {
    width: 100%;
}

#service_rechts > .serviceImage:first-child {
    margin-top: 36px;
}

.serviceImage {
    padding: 0;
}

.serviceImage {
    display: block;
    overflow: hidden;
    background: white;
    border-radius: 5px;
    box-shadow: $schattenU;
    position: relative;
    box-sizing: border-box;
}

.serviceImage div {
    height: 30px;
    line-height: 30px;
    color: $blau;
    text-align: center;
}

.jobs span:first-child {
    width: 65%;
    display: inline-block;
}

#shopLayer[data-pp="gloss"] #gloss_nav {
    display: none;
}

#shopLayer[data-pp="gloss"] .shop_tab_mitte {
    min-height: 500px;
    max-width: 1200px;
    margin: 0 auto;
}

#shopLayer[data-pp="gloss"] .tab_k {
    display: none;
}

#shopLayer[data-pp="gloss"] .shop_tab_mitte > div:nth-child(2) {
    margin-bottom: 240px;
    display: flex;
    flex-direction: column-reverse;
}

#shopLayer[data-dhe="Das_Hutlexikon"] .shop_tab_mitte > div:nth-child(2) {
    flex-direction: column;
}

#shopLayer[data-pp="gloss"] .shop_tab_mitte .location_headline {
    display: none;
}

#gloss_nav {
    margin: 0 0 30px 0;
}

#gloss_nav ul {
    display: grid;
    gap: 1vw;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
}

#shopLayer[data-pp="gloss"] .gloss_alph_tab a:hover nobr {
    color: #fff;
}

#shopLayer[data-pp="gloss"] .gloss_alph_tab a nobr {
    font-size: 20px;
}

#shopLayer[data-pp="gloss"] .gloss_box {
    display: grid;
    gap: 1vw;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

#shopLayer[data-pp="gloss"] .gloss_box a {
    padding: 9px 25px 12px 25px;
    background: #fff;
    display: inline-block;
    border-radius: 5px;
    box-shadow: $schattenU;
    min-height: 50px;
    font-size: 20px;
    text-align: center;
    transition: all 300ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

#shopLayer[data-pp="gloss"] .gloss_box a:hover {
    background: $blau;
    transition: all 300ms ease-in-out;
    transform: scale(1.2);
}

#shopLayer[data-pp="gloss"] .gloss_box a:hover span {
    color: #fff;
    transition: all 300ms ease-in-out;
}

#shopLayer[data-pp="gloss"] .gloss_box .gloss_alph_tab a {
    background: transparent;
}

#shopLayer[data-pp="gloss"] #banner_ct {
    margin-top: 30px;
    display: block;
    overflow: hidden;
}

#shopLayer[data-pp="gloss"] #banner_ct h1 {
    font-weight: bold;
    font-size: 28px;
}

#shopLayer[data-pp="gloss"] #banner_ct h2 {
    font-weight: bold;
    font-size: 20px;
    color: RGB(158, 158, 158);
    margin-bottom: 12px;
}

#shopLayer[data-pp="gloss"] span.gloss_cloud3 {
    font-size: 18px;
    font-weight: 300;
}

#shopLayer[data-pp="gloss"] .gloss_box .gloss_alph_tab span.gloss_alph {
    font-size: 100px;
    margin: 0 0 -20px 0;
    color: $blau;
    display: block;
}

.gloss_box .gloss_alph_tab {
    display: block;
    width: 100%;
    margin-top: 25px;
    grid-column-start: 1;
    grid-column-end: -1;
}

h1.gloss_titel {
    font-size: 28px;
    padding: 0 0 20px 0;
}

.gloss_text {
    display: block;
    overflow: hidden;
    background: white;
    border-radius: 5px;
    box-shadow: $schattenU;
    position: relative;
    box-sizing: border-box;
    padding: 26px;

    p {
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        margin: 0 0 20px 0;

        b {
            font-size: inherit;
            font-weight: 900;
        }
    }

    .wrapper {
        img {
            border-radius: 5px;
            margin: 0 0 20px 20px;
            max-width: 500px;
            align-self: baseline;
            float: right;
        }
    }

    .links {
        h3 {
            display: block;
            margin: 20px 0 20px 0;
            font-size: 16px;
        }

        a {
            background: $blau;
            padding: 10px 30px;
            margin: 0 10px 10px 0;
            border-radius: 5px;
            color: #fff;
        }
    }
}

.button_za_dk9 {
    background-image: url(#{$cdnPfad}/Zahlart-Keditkarte.png);
}

.button_za_dk8 {
    background-image: url(#{$cdnPfad}/Zahlart-Paypal.png);
}

.button_za_dk4 {
    background-image: url(#{$cdnPfad}/Zahlart-Nachnahme.png);
}

.button_za_dk3 {
    background-image: url(#{$cdnPfad}/Zahlart-Vorkasse.png);
}

.button_za_dk2 {
    background-image: url(#{$cdnPfad}/Zahlart-Lastschrift.png);
}

.button_za_dk7 {
    background-image: url(#{$cdnPfad}/Zahlart-Rechnung.png);
}

.kategorie-banner-all {
    padding-top: 70px;
    margin-top: -20px;
}

h1.kategorie-banner-headline {
    font-weight: bold;
    font-size: 4rem;
    margin: 0 0 15px 8px;
}

.kategorie-banner {
    display: flex;
}

.kategorie-banner a {
    margin: 0 8px;
    position: relative;
}

.kategorie-banner a img {
    transition: all 200ms ease-in-out;
}

.kategorie-banner a:hover img {
    transform: scale(1.1);
    transition: all 500ms ease-in-out;
}

.kategorie-banner a div {
    overflow: hidden;
    border-radius: 5px;
    display: grid;
}

.kategorie-banner a:hover span.button {
    background: $blau;
    color: #fff;
}

.kategorie-banner img {
    max-width: 100%;
    height: auto;
    width: 100%;
}

.kategorie-banner a span.button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
    text-transform: uppercase;
    width: 15vw;
    height: 2vw;
    transition: all 200ms ease-in-out;
    margin: 0 auto;
    background: #fff;
    color: #333;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1vw;
}

table[style*="#DD2222"],
.errorWrapper {
    padding: 18px;
    border-radius: 6px;
    background: #fff;
    border: 2px solid $rot !important;
    width: auto;
    margin: 6px auto;
    color: $schriftfarbe;
}

#fehler_zahlart table {
    background: #fff;
}

#fehler_zahlart table img {
    margin-right: 20px;
}

#fehler_zahlart table a {
    cursor: pointer;
}

.shop_tab_mitte #service_wrapper nav:hover:before li a {
    background: white url(#{$cdnPfad}/picture-navigation-r.svg) no-repeat 0% 50%;
}

#suche_topKategorien,
#suche_topMarken {
    flex: 1;
}

.sucheGross nav > div:nth-of-type(2) {
    width: 508px;
}

.suche_marke {
    width: 120px;
    height: 90px;
}

#suche_topMarken a,
#suche_topKategorien a {
    float: left;
    display: block;
    width: 33.33%;
    margin-bottom: 50px;
    height: 70px;
}

#suche_topKategorien a img {
    width: 60px;
    max-height: none;
    margin-bottom: 6px;
}

.sucheGross nav > div {
    float: left;
    width: 33.333%;
    text-align: center;
}

.detail-hersteller-logo {
    position: absolute;
    top: 15px;
    right: 5px;
    min-width: 160px;
}

.sucheGross nav {
    width: auto;
    display: flex;
}

.sucheGross .se.list_se.single {
    width: auto;
}

#cookieMeldung {
    cursor: pointer;
}

#cookieMeldung a:not(.cookieButtons) {
    display: inline;
}

u {
    text-decoration: underline;
    cursor: default;
}

.location_headline span:nth-of-type(2) {
    margin-left: -5px;
}

#artikeldetailsocials {
    overflow: hidden;
    padding: 10px 5px;
    margin: 5px 0;
    clear: both;
}

#socialbarbutton {
    width: 120px;
    height: 31px;
    background-image: url(#{$cdnPfad}/button_soc.png);
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: top left;
}

#socialbarbutton .socialinfo {
    display: none;
}

#socialbarbutton .socialinfo button {
    border: 0;
    color: white;
    background: #5ea9dd;
    cursor: pointer;
    padding: 6px;
    margin-top: 20px;
}

#socialbarbutton .socialinfo:before {
    content: " ";
    position: absolute;
    width: 20px;
    height: 20px;
    display: block;
    transform: rotate(45deg);
    margin-left: 1px;
    margin-top: -21px;
    border-left: 1px solid silver;
    border-top: 1px solid silver;
    background-color: white;
}

#socialbarbutton.clicked .socialinfo {
    position: absolute;
    margin-left: -4px;
    margin-top: 33px;
    z-index: 10;
    display: block;
    width: 277px;
    color: #000;
    background: #fff none repeat scroll 0% 0%;
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

#socialbarbutton .socialinfo p {
    margin: 0;
    padding: 0;
}

#aa_video > div:after {
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    pointer-events: bounding-box;
}

#aa_video > div:before {
    content: " ";
    width: 87px;
    height: 61px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 50%;
    background-image: url(#{$cdnPfad}/play_youtube.svg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: -44px;
    margin-top: -30px;
    pointer-events: bounding-box;
}

#aa_video > div.play:after,
#aa_video > div.play:before {
    display: none;
}

.unser-kommentar {
    position: relative;
    margin-bottom: 0 !important;
}

.separate-rating {
    .rating {
        float: right;
        flex-direction: row-reverse;
    }

    .rating-note {
        margin: 0 7px 0 0;
    }

    .rating:before {
        font-size: 16px;
    }
}

.separate-rating .rating-kommentar {
    margin: 30px 0 0 0;
    background: $blau;
    color: white;
    padding: 15px;
    line-height: 20px;
    border-radius: 5px;
}

.separate-rating .dreieck {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 30px solid $blau;
    margin-left: 20px;
    position: absolute;
    top: -15px;
    left: 10px;

    + .rating-kommentar:empty {
        display: none;
    }
}

.separate-rating .rating-kommentar:empty {
    + .dreieck {
        display: none;
    }
}

div.aa_rating_snippet {
    padding: 20px;

    .separate-rating {
        border-bottom: 1px solid #eee;
        padding: 20px 0;
    }
}

.aa_rating_snippet .separate-rating > b {
    font-size: 16px;
    display: block !important;
}

.aa_rating_snippet .rat_stars,
.aa_rating_snippet .rating-note {
    display: block;
    float: none;
}

.aa_rating_snippet .rat_stars {
    padding-left: 0;
}

.aa_rating_snippet .rating-kommentar {
    max-width: 70%;
}

.aa_rating_snippet .rat_stars:last-of-type {
    margin-right: 10px;
}

.aa_rating_snippet .separate-rating br:not(.clear) {
    display: none;
}

.aa_rating_snippet .separate-rating {
    font-size: 16px;
    width: 99%;
}

.aa_rating_snippet {
    position: relative;
    padding: 0 20px;
}

.aa_rating_snippet .rat_note td {
    padding-right: 10px;
}

.aa_rating_snippet .rat_note {
    flex: 1 0 100%;
}

.aa_rating_snippet .rat_product {
    order: 1;
    margin-right: 50px;
    width: unset;
}

.aa_rating_snippet .rat_image {
    flex: 0 0 auto;
    margin-right: 50px;
}

.aa_rating_snippet img.aa_rating_foto {
    height: 110px;
    margin-top: 30px;
}

.aa_rating_snippet .rat_2besten {
    display: none;
    margin-top: 40px;
    font-size: 16px;
    font-weight: bold;
}

.rating-kommentar:empty {
    display: none;
    margin: 0;
    padding: 0;
}

.separate-rating > b {
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
}

.separate-rating .rating-text {
    line-height: 135%;
    font-weight: 400;
}

.aa_rating_snippet .separate-rating .rating-text {
    width: 55%;
    display: inline-block;
}

.rating-properties {
    width: 40%;
    border-left: 1px solid #ddd;
    padding: 0 0 0 20px;
    float: right;
}

.aa_rating_snippet .rat_stars {
    margin-right: 30%;
}

.draggable-header .highslide-header {
    border: 0;
}

.aar_top .aa_rating_snippet {
    background: #fff;
}

.aa_rating_snippet .rat_kopf {
    border: 0;
    border-radius: 0;
    background-color: #fff;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

.aa_rating_snippet .rat_kopf_innen {
    border: 0;
    margin: 0;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 100%;
    justify-content: space-evenly;
    align-items: center;
}

.aa_rating_snippet .rat_kopf_innen a {
    flex: 1 1 100%;
}

.aa_rating_snippet .rat_krit {
    flex: 1 0 220px;
    order: 2;
}

.aa_rating_snippet .rat_krit td {
    padding-right: 5px;
}

.draggable-header .highslide-header .highslide-close a {
    display: block;
    height: 16px;
    width: 16px;
    background-image: url(#{$cdnPfad}/wk-x.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.draggable-header .highslide-header .highslide-close a:hover {
    background-image: url(#{$cdnPfad}/wk-x-w-a.svg);
    background-position: 0;
}

a img.aa_rating_foto {
    height: 220px;
}

.aa_rating_artikel {
    font-size: 20px;
    display: block !important;
    margin-bottom: 6px;
}

.rat_product,
.rat_krit {
    width: 30%;
    float: left;
    height: 100px;
    margin-top: 15px;
}

.rat_product td,
.rat_krit td {
    height: 22px;
}

.rat_krit > table > tbody > tr > td:nth-of-type(1) {
    min-width: 130px;
}

.rat_product > table > tbody > tr > td:nth-of-type(1) {
    min-width: 80px;
}

.rat_balken {
    background: #297ece;
}

.rat_typ_liste {
    margin: 0;
}

.rat_balken_hint {
    margin-right: 8px;
}

.rat_beste_hilfreiche,
.rat_beste_kritische {
    margin: 10px 10px 0 0;
}

.rat_2besten {
    margin-left: 0;
}

.rat_hilfreich {
    margin: 0;
    display: none;
}

.miniSlNav {
    cursor: pointer;
    height: 29px;
    top: 230px;
    width: 26px;
    opacity: 0;
}

.lang + .miniSlNav,
.lang + .miniSlNav + .miniSlNav {
    top: 398px;
}

#pgLoader {
    color: #297ece;
    padding: 200px 0;
    opacity: 0.8;
    background: #fff;
}

img.own-icons {
    transform: translate(0, -50%);
    position: absolute;
    margin: auto 20px;
    top: 50%;
    width: 55px;
}

img.own-icons + h3 + p,
img.own-icons + h3 {
    margin-left: 110px !important;
}

.se.list_se li:hover .azu_imgs {
    opacity: 1;
    transition: opacity 0.5s;
    position: absolute;
    z-index: 11;
}

.shBagInh ul li b {
    display: inline-block;
    line-height: 120%;
    margin-bottom: 0;
}

.shBagInh ul li div.WkartNr {
    margin-top: 3px;
}

.trustedshops input {
    background: transparent;
}

.wkSpez span {
    margin-left: 10px;
}

.wkSpez span:first-of-type {
    margin-left: 0px;
}

.login_o span {
    padding: 1%;
    display: block;
}

.location_headline {
    position: absolute;
    height: 103px;
    line-height: 104px;
}

#shopLayer[data-pp="suche"] .location_headline {
    line-height: 27px;
    margin-left: 13px;
}

.adress_tabelle .wkBox.rech_box {
    margin-left: 13px;
}

#mehr_farben:after,
#mehr_groessen:after {
    transform: rotate(-90deg);
    background: white url(#{$cdnPfad}/picture-navigation.svg) no-repeat 1px 3px;
    background-size: 7px;
}

.abschluss .order-info * {
    color: #a2abb8;
}

.abschluss .order-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    gap: 16px;
}

.abschluss .order-info > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.abschluss .order-info div span.box {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    position: relative;
    flex-direction: column;
    padding: 26px;
    box-sizing: border-box;
    flex-grow: 1;
    min-height: 100px;
}

.abschluss .order-info div.versandart span.box {
    justify-content: space-around;
    gap: 16px;
}

.abschluss .order-info div > p,
.abschluss .order-info div > p span {
    font-size: 16px;
    font-weight: bold;
}

.order-info span.box img {
    max-height: 40px;
    grid-area: img;
}

.abschluss .order-info div.versandart span.box a,
.abschluss .teil label {
    display: grid;
    grid-template-areas: "check img name price" "check img time price" "check img info price";
    grid-template-columns: auto auto auto 1fr;
    gap: 0 16px;
    position: relative;
    cursor: pointer;
    justify-content: start;
    align-items: center;
}

a[href="#aktiv"] span.exp-14 {
    display: block !important;
}

span.exp-14 {
    grid-area: info !important;
    text-align: left !important;
}

span.exvpreis,
.order-info span.box a span {
    grid-area: price;
}

.abschluss .order-info div.versandart span.box a div:nth-of-type(1) {
    grid-area: name;
    align-self: end;
    color: $bgrau;
    font-weight: 700;
}

.abschluss .order-info div.versandart span.box a div:nth-of-type(2) {
    grid-area: time;
    align-self: start;
}

.versandanschrift .box,
.rechnungsanschrift .box {
    white-space: nowrap;
}

.abschluss .order-info span.box a.edit {
    display: block;
    width: 22px;
    height: 22px;
    background: #a3abb8 50% 50% url(#{$cdnPfad}/edit.svg) no-repeat;
    background-size: 50% 50%;
    border-radius: 100px;
    position: absolute;
    top: 12px;
    right: 12px;
}

.abschluss .order-info {
    .zahlart {
        flex: 1 1 18%;
    }

    .prio-versand {
        flex: 1 1 70%;
    }

    .rechnungsanschrift,
    .versandanschrift {
        flex: 1 1 15%;
    }
}

.versandart span.box div.teil {
    position: relative;
    padding: 20px 0 0 0;
    border-top: 1px dotted #ddd;

    .text,
    .teil_info {
        margin-right: 20px;
    }

    span.teil_info {
        border: 2px solid #2aafba;
        width: 21px;
        text-align: center;
        height: 21px;
        line-height: 21px;
        border-radius: 50px;
        font-weight: 900;
        color: #2aafba !important;
    }
}

.abschluss .order-info .teil label span:last-child {
    margin-left: auto;
}

.versandart span.box div.teil span.noteil {
    padding-left: 70px;
    color: $rot;
    line-height: 55px;
}

.versandart span.box div.teil:empty {
    display: none;
}

.versandart span.box div.teil label {
    line-height: 52px;
    display: flex;
    cursor: pointer;
}

.abschluss .order-info div.versandart span.box a:before,
.versandart span.box div.teil label:before {
    content: "";
    width: 16px;
    height: 16px;
    background: #ddd;
    border-radius: 16px;
    cursor: pointer;
    margin: auto 0;
    flex-shrink: 0;
    grid-area: check;
}

.abschluss .order-info div.versandart span.box a:hover:before,
.versandart span.box div.teil:hover label:before {
    background: $blau;
}

.versandart span.box div.teil label.active:before {
    background: $blau;
}

.versandart span.box div.teil:active label.active:before {
    background: #ddd;
}

.abschluss .order-info .teil label span {
    color: $blau;
}

.abschluss .order-info div.versandart span.box a div {
    cursor: pointer;
    white-space: nowrap;
}

.abschluss .order-info div.versandart span.box a[data-aktiv="1"]:before {
    background: $blau;
}

.zk_versandarten {
    display: none;
}

.order-info span.box a span {
    text-align: right;
    color: $blau !important;
    white-space: nowrap;
}

#shopHeader > nav section ul.men_loginM {
    display: none;
}

#shopHeader > nav section li.menLogButtons {
    display: none;
}

.newsletter-reg div.form div.form_border {
    min-width: auto;
}

#pp_exp_btn {
    display: none;
}

#pp_express_link {
    background: url(#{$cdnPfad}/pay_paypal.svg), linear-gradient(0deg, #e7e9ec 0%, #f9f9fb 100%);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: 1px solid #989ba0;
    display: block;
    border-radius: 3px;
    margin-top: 20px;
    width: 230px;
    height: 46px;
    clear: both;
}

#tZoomViewer {
    z-index: 1005; // damit er über den controls liegt
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    width: 100% !important;
}

.vorteil.gr_1x0-5 figure {
    height: 90px;
}

.button_za_dk10 {
    background-image: url(#{$cdnPfad}/paydirekt_brand-a.svg);
    background-size: 90px;
}

.sucheGross.ajaxLoaded section:nth-of-type(1) {
    display: none;
}

.sucheGross section:nth-of-type(2) {
    display: none;
}

.sucheGross.ajaxLoaded section:nth-of-type(2) {
    display: block;
}

.sucheGross section:nth-of-type(2) ul li {
    opacity: 0;
}

.sucheGross.ajaxLoaded section:nth-of-type(2) ul li {
    @include browserPrefixes(animation, flyin 1s, webkit);
    @include browserPrefixes(animation-fill-mode, forwards, webkit);
}

@keyframes flyin {
    0% {
        transform: translateX(20px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(2) {
    animation-delay: 100ms;
}

.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(3) {
    animation-delay: 200ms;
}

.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(4) {
    animation-delay: 300ms;
}

.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(5) {
    animation-delay: 400ms;
}

.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(6) {
    animation-delay: 500ms;
}

.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(7),
.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(8),
.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(9),
.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(10),
.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(11),
.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(12),
.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(13),
.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(14),
.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(15),
.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(16),
.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(17),
.sucheGross.ajaxLoaded section:nth-of-type(2) ul li:nth-of-type(18) {
    display: none;
}

.amazon-widgets {
    display: flex;
    margin: 15px 0 0 0;
}

.amazon-widgets h1 {
    display: none;
}

#addressBookWidgetDiv,
#walletWidgetDiv {
    width: 50%;
}

#OffAmazonPaymentsWidgets0IFrame,
#OffAmazonPaymentsWidgets1IFrame {
    z-index: 1 !important;
}

.amazonWrapper {
    margin-top: 25px;
}

.zk_wk_summe [class="b"] {
    display: none !important;
}

.zk_wk_summe [zk_rab] {
    display: table-row !important;
}

.see_multi_submit {
    transition: all 0.3s;
}

.see_multi_submit.mode-close {
    color: $schriftfarbe;
}

.see_alle_dd ul .se_li_such input {
    transition: background-image 0.3s;
    background: white url(#{$cdnPfad}/search.svg) no-repeat 93% 50%;
    color: $schriftfarbe;
    width: 95%;
    box-sizing: border-box;
    height: 100%;
    position: absolute;
    background-size: auto 30%;
    left: 0;
    border: 1px solid $blau;
    text-indent: 16px;
    border-radius: 5px;
    margin: 6px;
}

.see_alle_dd ul .se_li_such input.mit-text {
    background-image: none;
}

.see_alle_dd ul .se_li_such {
    position: relative;
}

.se_keine_treffer {
    color: #fff;
    padding: 20px 11px !important;
}

.se_li_such input + .leere_input {
    position: absolute;

    /*background: url(#{$cdnPfad}/filter-x.svg) 50% 50% no-repeat;*/
    right: 0;
    top: 0;
    background-size: auto 30%;
    transition:
        opacity 0.3s,
        transform 0.3s;
    opacity: 0;

    @include browserPrefixes(transform, rotate(60deg), webkit);
}

.se_li_such input.mit-text + .leere_input {
    opacity: 1;
    transform: rotate(0deg);
    margin: 6px;
}

.ausgewaehlt a,
ul.ausgewaehlt li.active a {
    color: #fff !important;
}

.see_alle_dd .ausgewaehlt {
    background: $blau;
}

.see_alle_dd .ausgewaehlt div[class*="_kopf"]:after {
    background-image: url(#{$cdnPfad}/dropdown-arrow-w.svg);
}

.see_alle_dd .ausgewaehlt div[class*="_kopf"]:after {
    background-image: url(#{$cdnPfad}/dropdown-arrow-w.svg) !important;
}

ul#dd_zk1 li.se_li.se_li_such {
    display: none;
}

li.se_li.se_keine_treffer {
    line-height: 170%;
}

// .wiederArtikelZeigenBanner {
//     background: #2c97de;
//     width: 236px;
//     margin: 0 auto 20px auto;
//     height: 40px;
//     border-radius: 5px;
//     color: #fff;
//     text-align: center;
//     line-height: 40px;
//     cursor: pointer;
// }

.se.bannered {
    padding-bottom: 30px;
}

#bannerMehr,
#start-bannerMehr {
    cursor: pointer;
    color: #fff;
    background: $blau;
    padding: 10px 30px;
    border-radius: 5px;
    display: inline-block;
}

.men_head_5 a {
    color: $rot;
}

img[src=""] {
    display: none;
}

.her_graf {
    height: 50px;
}

.alle_ag_von_her a {
    text-align: center;
    overflow: hidden;
    font-size: 10px;
    display: block;
    padding: 5px;
}

#her_A,
#her_B,
#her_C,
#her_D,
#her_E,
#her_F,
#her_G,
#her_H,
#her_I,
#her_J,
#her_K,
#her_L,
#her_M,
#her_N,
#her_O,
#her_P,
#her_Q,
#her_R,
#her_S,
#her_T,
#her_U,
#her_V,
#her_W,
#her_X,
#her_Y,
#her_Z,
#her_0-9 {
    width: 226px;
    height: 176px;
    margin: 8px;
    float: left;
    text-align: center;
    background: $blau;
    border-radius: 5px;
    box-shadow: $schattenU;
    font-size: 100px;
    line-height: 183px;
    color: #fff;
}

.wrapper-hersteller-menu {
    display: flex;
    background: transparent;
    box-shadow: none;
    overflow: visible;
    width: 100%;
    min-height: 0;
    margin-top: 20px;
}

.wrapper-hersteller-menu div.hersteller {
    width: 236px;
    background: #64645f;
    text-align: center;
    float: right;
    order: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}

.se .hersteller_banner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.se .hersteller_banner li {
    text-align: center;
    color: #ffffff;
    font-size: 24px;
}

.se li.hersteller_banner {
    background: #64645f;
}

.se li.hersteller_banner p {
    position: absolute;
    top: 50%;
    width: 100%;
    color: #ffffff;
    font-size: 24px;
}

.se li.hersteller_banner ul {
    width: 100%;
}

.wrapper-hersteller-menu div.hersteller img {
    vertical-align: middle;
    width: 216px;
    height: auto;
    margin: 10px;
}

.wrapper-hersteller-menu div {
    background: #fff;
    border-radius: 5px;
    box-shadow: $schattenU;
}

.wrapper-hersteller-menu div.hersteller-menu {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    margin-right: 8px;
}

.wrapper-hersteller-menu div.hersteller-menu nav {
    display: flex;
    flex-wrap: wrap;
}

.wrapper-hersteller-menu div.hersteller-menu nav ul {
    width: 50%;
    margin: 0 0 20px 0;
    box-sizing: border-box;
    padding-right: 20px;
}

.wrapper-hersteller-menu div.hersteller-menu li {
    display: inline-block;
    height: 36px;
    line-height: 40px;
    margin-right: 6%;
    cursor: pointer;
}

.wrapper-hersteller-menu div.hersteller-menu li:hover a,
.wrapper-hersteller-menu div.hersteller-menu li.active a {
    color: #666;
}

.wrapper-hersteller-menu div.hersteller-menu li a:before,
.wrapper-hersteller-menu div.hersteller-menu li.active a:before {
    content: "";
    width: 12px;
    height: 13px;
    background: url(#{$cdnPfad}/dropdown-arrow-active.svg) 0 0 no-repeat;
    display: inline-block;
    transform: rotate(-90deg);
    position: absolute;
    left: -30px;
    opacity: 0;
}

.wrapper-hersteller-menu div.hersteller-menu li:hover a:before,
.wrapper-hersteller-menu div.hersteller-menu li.active a:before {
    left: -12px;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.wrapper-hersteller-menu div.hersteller-menu li a {
    color: $blau;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    display: block;
    line-height: 100%;
}

.wrapper-hersteller-menu div.hersteller-menu li.head {
    font-size: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    display: block;
    width: auto;
    height: auto;
    line-height: normal;
    padding-right: 0;
    margin-right: 0;
}

.wrapper-hersteller-menu section.w-4 {
    width: 100%;
}

#sitemap {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin: 0 auto;
    width: 45%;
    background: #ffffff;
}

#sitemap .col {
    display: inline-block;
}

#sitemap .title {
    padding: 8px;
    margin: 8px;
}

#sitemap span {
    padding: 4px 8px;
    background: $blau;
    border-radius: 5px;
    color: #ffffff;
    display: inline-block;
    margin-bottom: 3px;
}

#sitemap .her {
    padding-left: 30px;
}

#sitemap nobr {
    line-height: 28px;
}

#shopLayer[data-dhe="sitemap"] .tab_k {
    width: 45%;
    margin: 0 auto;
    padding: 10px 0;
}

.artikel_andere_groesse {
    background: white;
    border-radius: 5px;
    border: 2px solid RGB(42, 175, 186);
    margin: 16px 0 0 0;
    padding: 16px;
}

#preisDiv {
    font-weight: bold;
    font-size: 44px;
    color: $blau;
    text-align: right;
}

.se_banner b {
    font-size: inherit;
}

.sale-banner {
    height: 396px;
    background: $rot;
}

#k_wk:hover:after {
    transition-delay: 0s;
    transition: all 200ms ease-in-out;
    opacity: 1;
}

#k_merk:hover:after {
    transition-delay: 0s;
    transition: all 200ms ease-in-out;
    opacity: 1;
}

.vote {
    position: fixed;
    right: 0;
    top: 251px;
    z-index: 100;
    width: 160px;
}

body #tsbadge4_db8d3657bdbe440c985ae127463eaad4 {
    z-index: 2 !important;
}

.tabelle_zk textarea {
    position: absolute;
    left: -30000px;
}

.startseite .no_desktop {
    display: none;
}

.startseite .m_nav {
    max-width: 725px;
}

.startseite .m_nav a {
    border: 2px solid #ccc;
    padding: 20px;
    font-size: 4vw;
    font-weight: 300;
    flex-grow: 1;
    margin: 4px;
    border-radius: 5px;
}

.startseite > figure {
    text-align: center;
    margin: 0 0 50px 0;
    max-width: 1515px;
}

.startseite_top {
    display: flex;
    justify-content: center;
}

.startseite_top .flexitem {
    margin: 0 8px;
}

.startseite figure.startseite_top img {
    width: 100%;
    height: auto;
    max-width: 740px;
    border-radius: 5px;
    opacity: 0.9;
}

.startseite span.button {
    background: #fff;
    color: #333 !important;
}

.startseite_top span.button {
    bottom: 3vw;
    font-size: 1vw;
    text-transform: uppercase;
    width: 10vw;
    height: 2vw;
    padding: 0 1vw;
    line-height: 2vw;
    transition: all 900ms ease-in-out;
}

.startseite_top:hover #a {
    opacity: 0.5;
    transform: scale(0.99);
    transition: all 600ms ease-in-out;
}

.startseite_top a:hover span.button {
    color: $blau;
    transition: all 100ms ease-in-out;
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.1);
}

.startseite_top #a:hover {
    opacity: 1 !important;
    transform: scale(1.02) !important;
}

#a:hover + #b {
    opacity: 0.5;
    transform: scale(0.99);
    transition: all 600ms ease-in-out;
}

.startseite_top a img {
    transition: all 600ms ease-in-out;
}

.startseite_top a:hover img {
    transition: all 200ms ease-in-out;
    transform: scale(1.02);
    transform-origin: 50% 50%;
    opacity: 1;
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.1);
}

.startseite_top a:hover .button {
    transform: scale(1.02);
}

.flexitem {
    position: relative;
    text-align: center;
}

.startseite .headline-h2 {
    text-align: center;
    margin: 0 0 20px 0;
    color: $blau;
    font-size: 30px;
    display: block;
}

.top_article ul {
    max-height: 685px;
    overflow: hidden;
}

.benefits {
    max-height: 230px;
    overflow: hidden;
}

.startseite .se.list_se .se_banner.service.gr_1x0-5 {
    height: 168px;
    min-height: 168px;
}

.startseite .se.list_se.col-1 {
    width: 1008px;
    margin: 0;
    float: left;
}

.startseite .se.list_se.col-2 {
    width: 504px;
    margin: 0;
    float: left;
}

.startseite .se .cat {
    color: #fff;
}

.startseite .se .cat a {
    cursor: pointer;
}

.startseite .se .cat img {
    width: 100%;
    height: auto;
}

.startseite .se .cat.men_aug2_189 {
    background-image: url(#{$cdnPfad}/icon-trilby-01.svg);
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-size: 70%;
}

.startseite .finder {
    max-height: 400px;
    overflow: hidden;
}

.banner-finder p,
.banner-finder span {
    color: #fff;
}

.finder span.button {
    bottom: 20px !important;
    display: inline-block;
    position: absolute;
    margin: 0 auto;
    width: 200px;
    text-align: center;
    left: 0;
    right: 0;
}

.banner-finder img {
    width: 36%;
    margin-top: 20px;
}

.startseite .se_banner span.button {
    bottom: 8vh;
}

.service figure {
    background-size: 36% !important;
}

.service.stoff figure {
    background-image: url(#{$cdnPfad}/icon-stoff.svg);
}

.service.delivery figure {
    background-image: url(#{$cdnPfad}/icon-delivery.svg);
}

.service.size figure {
    background-image: url(#{$cdnPfad}/icon-size.svg);
}

.service.voucher figure {
    background-image: url(#{$cdnPfad}/icon-voucher.svg);
}

.service.faq figure {
    background-image: url(#{$cdnPfad}/service-faq.svg);
}

.service.payment figure {
    background-image: url(#{$cdnPfad}/benefit-payments-euro.svg);
    background-size: 27% !important;
}

.service figure {
    width: 100%;
    height: 70%;
    background: $blau no-repeat 50%;
    display: block;
    cursor: pointer;
}

.startseite .service {
    max-height: 240px;
    overflow: hidden;
}

.se > li.gr_2x0-5 {
    height: 155px;
    width: 488px;
    min-height: 0;
}

.startseite .blog {
    text-align: left;
    padding: 20px;
}

.startseite .blog > span a {
    font-size: 18px;
    color: $blau;
    margin-bottom: 10px;
    display: inline-block;
}

.startseite .blog li {
    line-height: 230%;
    color: $blau;
}

.startseite .blog li span {
    color: #827c73;
    padding-right: 40px;
}

.startseite .se.list_se.col-1 {
    display: none;
}

.startseite .cat .se {
    max-height: 684px;
    overflow: hidden;
}

.zahlart span.box[data-za] {
    background: white 50% 50% no-repeat;
    font-size: 0 !important;
    background-size: 150px;
}

.zahlart span.box[data-za*="redit"] {
    background-image: url(#{$cdnPfad}/payment-creditcard-a.svg);
}

div.zahlart span.box[data-za*="PayPal"],
div.zahlart span.box[data-za*="Paypal"],
.wkBox[data-za*="Paypal"],
.wkBox[data-za*="paypal"] {
    background-image: url(#{$cdnPfad}/payment-paypal-a.svg);
}

.zahlart span.box[data-za*="astschr"] {
    background-image: url(#{$cdnPfad}/payment-ec-bank-a.svg);
}

.zahlart span.box[data-za*="orkasse"],
.zahlart span.box[data-za*="berweisung"],
.wkBox[data-za*="orauskasse"] {
    background-image: url(#{$cdnPfad}/payment-vorkasse-a.svg);
}

.zahlart span.box[data-za*="achnah"] {
    background-image: url(#{$cdnPfad}/payment-nachnahme-a.svg);
}

.zahlart span.box[data-za*="echnung"] {
    background-image: url(#{$cdnPfad}/payment-rechnung-a.svg);
}

.zahlart span.box[data-za*="aydirek"] {
    background-image: url(#{$cdnPfad}/paydirekt_brand.svg);
    background-size: 150px;
}

.zahlart span.box[data-za*="mazon"] {
    background-image: url(#{$cdnPfad}/amazon_pay_brand.svg);
    background-size: 150px;
}

#suchoverlay {
    background-color: $blau !important;
}

.accent-color {
    color: $blau !important;
}

#suchoverlay .empty > #suchStr {
    border-radius: 20px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

#suchoverlay.empty {
    background-color: rgba(0, 0, 0, 0.6) !important;
}

.wrapper-hersteller-menu {
    display: flex;
    background: transparent;
    box-shadow: none;
    overflow: visible;
    width: 100%;
    min-height: 0;
    margin-top: 20px;
}

.wrapper-hersteller-menu div.hersteller {
    width: 236px;
    background: #64645f;
    text-align: center;
    float: right;
    order: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}

.wrapper-hersteller-menu div.hersteller img {
    vertical-align: middle;
    width: 216px;
    height: auto;
    margin: 10px;
}

.wrapper-hersteller-menu div {
    background: #fff;
    border-radius: 5px;
    box-shadow: $schattenU;
}

.wrapper-hersteller-menu div.hersteller-menu {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    margin-right: 8px;
}

.wrapper-hersteller-menu div.hersteller-menu nav {
    display: flex;
    flex-wrap: wrap;
}

.wrapper-hersteller-menu div.hersteller-menu nav ul {
    width: 50%;
    margin: 0 0 20px 0;
    box-sizing: border-box;
}

.wrapper-hersteller-menu div.hersteller-menu nav ul:last-child {
    margin: 0;
}

.wrapper-hersteller-menu div.hersteller-menu li {
    display: inline-block;
    height: 36px;
    line-height: 40px;
    margin-right: 6%;
    cursor: pointer;
}

.wrapper-hersteller-menu div.hersteller-menu li:last-child {
    margin-right: 0;
}

.wrapper-hersteller-menu div.hersteller-menu li:hover a,
.wrapper-hersteller-menu div.hersteller-menu li.active a {
    color: #666;
}

.wrapper-hersteller-menu div.hersteller-menu li a:before,
.wrapper-hersteller-menu div.hersteller-menu li.active a:before {
    content: "";
    width: 12px;
    height: 13px;
    background: url(#{$cdnPfad}/dropdown-arrow-active.svg) 0 0 no-repeat;
    display: inline-block;
    transform: rotate(-90deg);
    position: absolute;
    left: -30px;
    opacity: 0;
}

.wrapper-hersteller-menu div.hersteller-menu li:hover a:before,
.wrapper-hersteller-menu div.hersteller-menu li.active a:before {
    left: -12px;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.wrapper-hersteller-menu div.hersteller-menu li a {
    color: $blau;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    display: block;
    line-height: 100%;
}

.wrapper-hersteller-menu div.hersteller-menu li.head {
    font-size: 20px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    display: block;
    width: auto;
    height: auto;
    line-height: normal;
    padding-right: 0;
    margin-right: 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

@import "highslide";

#cssLoaded {
    position: absolute;
    width: 20px;
    height: 20px;
    left: -100px;
    bottom: 0;
}

#aa_text {
    margin-top: 0;
}

.sucheGross {
    width: 756px;
    font-size: 32px;
    padding: 0;
}

#shopMitte2 {
    margin-left: unset !important;
}

div[id*="minimized-trustbadge"] {
    right: 5px !important;

    &:hover {
        border: none !important;
    }
}

.ortVorschlagListe {
    margin: 10px 16px 0px 16px;
    text-align: left;
    cursor: pointer;
}

.ortVorschlag {
    color: $blau;
}

//banner UV Schutz (Temp)
#banner_1116 {
    display: flex;
    justify-content: center;
}

.mfp-wk-gutschein-popup {
    .mfp-container {
        height: auto;

        .popup-nicht-gehen {
            padding: 30px 20px 30px 50px;
            margin: 0 auto;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            display: flex;

            img {
                object-fit: contain;
                margin: 0 50px 0 0;
            }

            .angebot {
                text-align: left;
                color: $blau;
                font-size: 36px;
                font-weight: 700;
                text-transform: uppercase;
            }

            .einloesen {
                display: block;
                background: $blau;
                border-radius: 5px;
                min-width: 250px;
                cursor: pointer;

                a {
                    color: #fff;
                    padding: 10px;
                    display: block;
                }
            }

            .text {
                margin: 30px 0;
            }
        }
    }
}

@media (max-width: 760px) {
    .serviceVideo iframe {
        height: 395px;
    }

    .ortVorschlag {
        line-height: 45px;
        font-size: 30px;
    }

    .mfp-wk-gutschein-popup {
        .mfp-container {
            .popup-nicht-gehen {
                width: 85%;

                .text,
                .einloesen {
                    font-size: 26px;
                }

                .mfp-close {
                    font-size: 45px;
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
}

@media (max-width: 1899px) {
    #pacerGoOnButton {
        grid-column-end: span 6;
    }
}

@media (max-width: 1653px) {
    #pacerGoOnButton {
        grid-column-end: span 5;
    }
}

@media (max-width: 1404px) {
    #pacerGoOnButton {
        grid-column-end: span 4;
    }
}

@media (max-width: 1179px) {
    #pacerGoOnButton {
        grid-column-end: span 3;
    }

    .abschluss .order-info div.versandart span.box a div {
        margin-left: 0;
    }
}

@media (max-width: 760px) {
    .abschluss .order-info div.versandart span.box {
        gap: 36px;
    }

    #pacerGoOnButton {
        grid-column-end: span 2;
    }

    // (Doppel) Klick-Bug für iPhone behoben
    .se > li:hover .azu_imgs {
        transition: unset;
        display: none;
        opacity: 0;
    }
}

$base-font-size: 5px;

$switch-width: 9em;
$switch-size: 4.6em;
$switch-space: 0.2em;

$switch-off-padding: 0em;
$switch-on-padding: $switch-size - $switch-space;
$switch-button-size: $switch-size - $switch-space * 2;

$switch-off-border: #eee;
$switch-on-color: $blau;
$switch-off-color: #eee;
$switch-toggle-color: #fff;

$switch-animation-bounce-distance: 0.4em;

input.switch {
    font-size: $base-font-size;
    position: relative;
    display: inline-block;
    appearance: none;
    width: $switch-width;
    height: $switch-size;
    line-height: $switch-size;
    border-radius: $switch-size / 2;
    margin: 0;
    padding: 0;
    box-shadow: inset 0 0 0 $switch-space $switch-off-border;
    outline: 1px solid transparent !important;
    cursor: pointer;
    border: none;
    background: $switch-off-color;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    transition:
        box-shadow 0.3s ease-in-out,
        padding 0.25s ease-in-out;
    transition-delay: 0.1s, 0s;

    &:checked {
        transition-delay: 0s, 0s;
        box-shadow: inset 0 0 0 ($switch-size / 1.75) $switch-on-color !important;
        padding-left: $switch-on-padding;
    }

    /* Animations if supported */
    &.hit {
        animation: slide-off 0.35s ease both;
    }

    &.hit:checked {
        animation: slide-on 0.35s ease both 0.05s;
    }

    &::before,
    &::after {
        content: "";
    }

    &::after {
        /* Increases hit area */
        $size-adjust: -($switch-button-size / 3);

        position: absolute;
        top: $size-adjust;
        left: $size-adjust;
        bottom: $size-adjust;
        right: $size-adjust;
    }

    &::before {
        display: inline-block;
        height: $switch-button-size;
        width: $switch-button-size;
        margin: $switch-space 0 0 $switch-space;
        background-color: $switch-toggle-color;
        border-radius: $switch-button-size / 2;
        box-shadow:
            0 $switch-space ($switch-space * 2) 0 rgba(0, 0, 0, 0.2),
            0 0 ($switch-space / 2) ($switch-space / 2) rgba(0, 0, 0, 0.1);
        transition: all 0.1s ease 0.1s;
    }

    &:active::before {
        transition: all 0.1s ease 0.05s;
    }

    &.touch:hover::before {
        transition: all 0s ease 0s;
    }

    &:active::before,
    &.touch:hover::before {
        width: $switch-button-size + $switch-space * 4;
    }

    &:active:checked::before,
    &.touch:hover:checked::before {
        margin-left: -($switch-space * 3);
    }
}

@keyframes slide-on {
    0% {
        padding-left: $switch-off-padding;
    }

    60% {
        padding-left: $switch-on-padding + $switch-animation-bounce-distance;
    }

    100% {
        padding-left: $switch-on-padding;
    }
}

@keyframes slide-off {
    0% {
        padding-left: $switch-on-padding;
        text-indent: 0;
    }

    60% {
        padding-left: $switch-off-padding;
        text-indent: -$switch-animation-bounce-distance;
    }

    100% {
        padding-left: $switch-off-padding;
        text-indent: 0;
    }
}

#aa_mitte figure iframe {
    height: 488px;
    z-index: 1003 !important; // damit es zwischen controls und teilen buttons ist
}

.iframe-no-cookie-meldung {
    background: #ddd;
    background-blend-mode: multiply;
    padding: 30px 20px;
    text-align: left;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5px;

    &.youtube {
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: 350px !important;
        height: 100%;
        z-index: 9999;

        &:after {
            content: "";
            background: url(#{$cdnPfad}/b/youtube-icon.svg);
            position: absolute;
            right: 10px;
            bottom: 3px;
            background-position: center !important;
            background-repeat: no-repeat !important;
            height: 54px;
            width: 54px;
            z-index: 999999999;
        }

        + iframe {
            height: 620px;
            width: 629px;
            position: absolute;
            top: 7.5%;
            left: 7.5%;
            z-index: 2;
        }
    }

    .wrapper {
        background: white;
        height: max-content;
        display: flex;
        justify-content: center;
        width: 80%;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: $schattenU;
    }

    .switch {
        background: #c1c1c1 !important;
        min-width: 45px;
    }

    .flex {
        flex-direction: column;
        padding: 0 20px;

        .headline {
            display: block;
            font-weight: 700;
            margin-bottom: 5px;
        }
    }

    &.hidden {
        display: none;
    }
}

.zahlart .box[data-za=""] {
    background-image: url(#{$cdnPfad}/b/payment_amazon.svg) !important;
}

// XMAS mit Aktion dort wird die Klasse .xmas gesetzt
#aa_detail.xmas {
    .vorteil.versand figure {
        background-image: url(#{$cdnPfad}/xmas-benefits-delivery.svg);
    }

    .vorteil.umtausch figure {
        background-image: url(#{$cdnPfad}/xmas-benefits-retoure.svg);
    }

    .vorteil.zahlung figure {
        background-image: url(#{$cdnPfad}/xmas-benefits-payment.svg);
    }

    #basket-wrapper .vorteil figure {
        background-size: 70%;
        background-color: #68b592 !important;
    }
}

#shopHeader .header-top.xmas {
    background: #68b592 !important;
}

//Blackweek
.se.list_se li .se_unten_blackweek {
    background: #262228;
    position: absolute;
    width: auto;
    padding: 6px;
    line-height: 12px;
    color: #fff;
    font-size: 10px;
    font-weight: 100;
    right: 0;
    bottom: 0;
    border-top-left-radius: 5px;
    letter-spacing: 0.3px;

    text-transform: uppercase;
    text-align: left;

    span {
        font-size: 10px;
        display: block;
        color: #fff;
        letter-spacing: 0;
    }
}

@media (max-width: 760px) {
    span.exp-14 {
        line-height: 130%;
    }

    .se.list_se li .se_unten_blackweek {
        right: -7px;
        bottom: -2px;
    }

    .se.list_se li.artikel_2 .se_unten_blackweek {
        bottom: -16px;
    }

    .BreadcrumbList .location_crumb svg{
        transform: scale(2);
        margin-bottom: 5px;
    }
}

.mail_austragen_txt {
    background: #fff;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 750px;
    margin: 50px auto;
    font-size: 16px;
}

//BLACKWEEK AD

.black-sale {
    margin-top: 16px;
    border-radius: 5px;
    padding: 20px;
    background: linear-gradient(0deg, #262228 0%, #1a191c 100%);
    color: #fff;
    font-weight: 900;
    font-size: 40px;
    line-height: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div {
        display: flex;
        flex-direction: column;
        color: #fff;
        align-self: end;
        margin-bottom: 7px;

        .code {
            font-size: 16px;
            font-weight: 800;
            line-height: 80%;
        }
    }

    .black-badge {
        width: 80px;
        height: 80px;
    }

    span {
        color: #fff;
    }
}

@media (max-width: 1653px) and (min-width: 768px) {
    .black-sale {
        align-items: flex-start;
    }

    .black-text {
        order: 1;
        width: 100px;
    }

    .black-badge {
        order: 2;
    }
}

@media (max-width: 768px) {
    .black-sale {
        div {
            font-size: 24px;

            span.code {
                font-size: 34px;
                line-height: 140%;
            }
        }

        .black-badge {
            width: auto;
            height: auto;
        }
    }
}

//erstmal schnell raus weil umfrage komische fragen stellt
.umfrage-fertig p,
.umfrage-fertig a {
    display: none !important;
}

//Uptain
.nonIE {
    color: white !important;
}

.rating:before {
    content: "★★★★★"; // aus der critical entfernt, weil das mit den Sternen nicht funktioniert, das die mit ISO-8859-1 kodiert sind und dnan nur Grütze rauskommt
}

//als Leerzeichen nach "mehr" im AD
#aa_feat > a:before {
    margin-right: 6px;
}
