.serienartikel {
    max-width: 740px;
    margin: 0 auto;

    h1,
    h2 {
        font-size: 24px;
        font-weight: 700;
    }

    h2 {
        margin: 50px 0 10px 0;
    }

    .einleitung {
        padding: 20px 0;
    }

    button {
        background: $blau;
        color: #fff;
        padding: 10px;
        min-width: 150px;
        margin: 0 20px 0 0;
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;
    }

    .box {
        margin: 0 !important;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 236px);
        grid-template-rows: auto auto 330px;
        margin: 20px 0 40px 0;
        gap: 16px;

        &.artikel_3 {
            .text {
                grid-column: 1 / 3;
                grid-row: 2 / 2;
            }

            .vorteile {
                grid-column: 3 / 3;
                grid-row: 2 / 2;
            }
        }

        .box.text {
            font-size: 16px;
        }
    }

    .img-wrapper {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        position: relative;

        .text {
            position: absolute;
            top: 15px;
            left: 15px;
            font-size: 18px;
            font-weight: 700;
        }

        > img {
            border-radius: 5px;
            box-shadow: $schattenU;
        }
    }

    .list_se {
        grid-column: 1 / 1;
        grid-row: 3 / 3;
        margin: 0 !important;

        .artikel_1,
        .artikel_2 {
            height: 322px !important;
        }

        > li.artikel_2,
        > li.artikel_1 {
            figure {
                height: 204px !important;

                > img {
                    max-width: 204px !important;
                }
            }
        }

        .artikel_1:not(.se_az625) {
            grid-row-end: unset !important;
        }
    }

    .text {
        grid-column: 1 / 4;
        grid-row: 2 / 2;
    }

    .vorteile {
        grid-column: 3 / 3;
        grid-row: 3 / 3;
        max-height: 322px;
        box-sizing: border-box;

        .headline {
            font-weight: 700;
            padding-bottom: 15px;
            font-size: 16px;
        }

        ul {
            list-style: none;

            > li {
                display: flex;
                align-items: center;
                font-size: 16px;
                padding-bottom: 5px;

                &:before {
                    content: "• ";
                    color: $blau;
                    font-size: 20px;
                    padding-right: 5px;
                }
            }
        }
    }
}

.gesichtsformen {
    max-width: 1260px;
    margin: 0 auto;

    &.subpage {
        margin: unset;
    }

    // Banner für Subpages
    .banner {
        grid-row-end: span 4;
        grid-column-end: span 3;
        display: flex;
        align-items: center;
        padding: 20px;
        box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        > img {
            width: auto;
            max-width: 100%;
            height: 250px;
            margin-right: 15px;
        }

        .content {
            opacity: 1 !important;
            display: flex;
            flex-direction: column;
            flex: 1;
            text-align: initial;

            > h1 {
                text-align: initial;
                font-size: 22px;
            }
        }
    }

    h1 {
        font-size: 26px;
        font-weight: 700;
        text-align: left;
        margin: 25px 0 10px 0;
    }

    p {
        margin: 0 0 10px 0;
    }

    .startimg img {
        max-width: 240px;
    }

    .row {
        display: flex;

        h2 {
            font-size: 22px;
            font-weight: 700;
            padding-bottom: 10px;
        }

        p {
            font-size: 14px;

            strong {
                display: inline;
                font-weight: 700;
            }
        }

        strong {
            display: block;
            font-weight: 700;
            padding: 10px 0 5px 0;
            font-size: 14px;
        }

        ul {
            li {
                list-style-type: disc;
                margin-left: 20px;
                font-size: 14px;
            }
        }
    }

    .content {
        flex: 0 1 30%;
    }

    .bilder {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        flex: 1 0 70%;

        .items {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            &:first-child {
                margin-bottom: 30px;
            }

            img {
                max-width: 100%;
                height: 130px;
            }

            > div {
                display: flex;
                flex-direction: column;
                align-items: center;

                &:first-child {
                    margin-right: 30px;
                }

                > span {
                    font-size: 16px;
                }

                a {
                    color: #ffffff;
                    background: $blau;
                    padding: 10px 30px;
                    border-radius: 5px;
                    font-size: 14px;

                    &:hover {
                        background: #2aafba;
                    }
                }
            }
        }
    }
}

.sichere-zahlungsarten {
    .zahlungen {
        display: flex;
        flex-wrap: wrap;

        img {
            width: -webkit-fill-available;
            width: -moz-available;
            width: fill-available;
        }

        > a {
            flex: 0 1 calc(25% - 16px);
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: $schattenU;
            border-radius: 5px;
            height: 120px;
            box-sizing: border-box;
            padding: 10px;
            margin: 0px 16px 16px 0px;
        }
    }

    .texte {
        display: flex;
        flex-wrap: wrap;

        > .box {
            flex: calc(50% - 50px);
            margin: 16px 16px 0 0;

            &.full {
                flex: 1 0 calc(100% - 50px);
            }
        }

        p {
            margin-bottom: 20px;
        }

        strong {
            font-weight: 700;
        }
    }
}

//hutspanner-korkeinlagen
.hutspanner {
    max-width: 1000px;
    margin: 0 auto;

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 16px;
    }

    h1,
    h2 {
        margin-bottom: 20px;
        font-weight: 700;
    }

    .card {
        box-shadow: $schattenU;
        background: #ffffff;
        border-radius: 5px;
        padding: 16px;
    }

    .row {
        display: flex;
        margin-bottom: 15px;

        p:not(:first-child) {
            margin: 20px 0;
        }
    }

    .artikel_1 {
        height: 326px;
        flex: 1 0 236px;
        background: white;
        margin-right: 15px;

        figure {
            display: block;
            width: 236px;
            height: 204px;
            text-align: center;
            position: relative;
            overflow: hidden;
        }
    }

    .row-1 {
        .card {
            flex: 0 1 50%;
        }

        img {
            flex: 1;
            margin-left: 15px;
            border-radius: 5px;
            max-width: 50%;
        }
    }

    .row-2 .card {
        img {
            float: right;
            height: 170px;
            margin: 0 10px 0 50px;
        }
    }

    .row-3 {
        .artikel_1 {
            margin-right: 0;
            margin-left: 15px;
        }

        span {
            max-width: 190px;
            margin-left: 10px;
        }

        .flex {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
        }
    }

    @media (max-width: 760px) {
        h1,
        h2 {
            font-size: 30px;
        }

        p {
            font-size: 26px;
        }

        .artikel_1 {
            figure {
                width: 100%;
            }
        }

        .card {
            flex: 1 0 50%;
            box-sizing: border-box;
        }

        .row-1 {
            flex-direction: column;

            img {
                max-width: 100%;
                margin: 15px 0;
            }
        }

        .row-2 {
            .card {
                img {
                    float: inherit;
                    margin: 10px 0;
                    display: block;
                }
            }
        }

        .row-3 {
            .artikel_1 {
                order: -1;
                margin-left: 0;
                margin-right: 15px;
            }

            span {
                max-width: unset;
            }

            .flex {
                flex-direction: column;

                iframe {
                    flex: 1 0 100%;
                    width: 100%;
                    height: 230px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

//umtauschrecht.html
#service_mitte.artikel-rueckversand {
    max-width: 600px;

    .abschnitt {
        display: flex;
        flex-wrap: wrap;

        h3 {
            font-size: 18px;
            flex: 1 0 100%;
        }

        .content {
            display: flex;
            justify-content: space-evenly;
            padding-top: 15px;
            flex: 1;
        }

        .img {
            flex: 0 1 30%;
            max-height: 100px;
        }

        .text {
            flex: 0 1 60%;
            font-weight: 400;
        }
    }

    .einleitung {
        .text {
            margin-bottom: 0;
        }
    }

    @media (max-width: 760px) {
        max-width: unset;

        h1,
        h2,
        h3,
        b {
            font-size: $mobile-font-size;
        }

        .text b {
            font-size: $mobile-font-size;
        }

        .ruecksendung {
            margin-top: 20px;
        }

        .einleitung {
            .text {
                font-weight: 400;
            }
        }
    }
}

.low-articels {
    display: grid;
    max-width: 740px;
    margin: 0 auto;
    grid-template-columns: 236px minmax(1px, 1fr);
    grid-template-rows: auto;
    gap: 16px;

    &.low-articels:has(.artikel-col ul:nth-last-child(n + 4), .artikel-col ul:nth-last-child(n + 4) ~ .artikel-col ul) {
        max-width: 1244px;
        grid-template-columns: 740px minmax(1px, 1fr);

        .headerbild {
            grid-column-end: 2;
        }

        .artikel-col {
            gap: 16px;
            display: grid;
            grid-template-columns: repeat(auto-fill, 236px);

            .list_se:has(li.artikel_2) {
                grid-row-end: span 4;
            }
        }

        .content {
            grid-row-start: 1;
            grid-row-end: span 3;
        }
    }

    .content {
        h1 {
            font-size: 26px;
            padding: 0 0 10px 0;
        }

        p {
            padding: 10px 0;
        }

        ul {
            margin: 5px 0 10px 0;

            li {
                list-style-type: disc;
                margin: 0 0 0 30px;
            }
        }

        h3 {
            font-size: 16px;
            color: #737982;
            margin: 20px 0 0 0;
            font-weight: 700;
        }

        a {
            color: $blau;
        }

        b,
        strong {
            font-weight: 700;
        }
    }

    .headerbild {
        grid-column-start: 0;
        grid-column-end: span 2;
        border-radius: 5px;
        box-shadow: $schattenU;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .artikel-col {
        display: flex;
        flex-direction: column;
        gap: 34px;
    }

    .list_se {
        display: grid;
        grid-template-columns: 236px;
        margin: 0;
        padding: 0;
        gap: 16px;
        width: auto !important;
        background-image: none;
        grid-auto-rows: max-content;

        &:has(li:first-child:last-child) {
            display: none;
        }
    }

    .content {
        grid-column: 2;
        grid-row: 2;

        &.box {
            margin: 0;
        }
    }

    .berater {
        grid-column-end: span 1;
        grid-row-end: span 2;
        background: #fff;
        position: relative;

        &:only-child {
            display: none;
        }

        p {
            max-height: 35px;
            overflow: hidden;
            padding: 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    .checkbox {
        height: 15px;
        width: 55px;
        position: absolute;
        right: 14px;
        bottom: 18px;
        z-index: 1;
        cursor: pointer;

        &.active {
            + .content > p {
                max-height: unset;
            }
        }
    }

    figure {
        background: #fff !important;
    }

    .mehr {
        flex-direction: unset;
        gap: 8px;
        color: $blau;
        height: auto;
        align-items: center;
    }

    .arrow {
        border: solid black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        width: 7px;
        height: 7px;
        border-color: $blau;
    }

    .up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

.hutformen {
    display: flex;
    gap: 16px;

    .flex {
        display: flex;
        gap: 10px;
        flex-direction: row;
    }

    .spalte-1 {
        flex: 0 1 calc(60% - 16px);

        img {
            max-width: 100%;
            height: 200px;
        }

        a {
            color: #fff;
            background: #2f80cb;
            padding: 10px 30px;
            border-radius: 5px;
            font-size: 14px;
            display: block;
            max-width: fit-content;
            margin-top: 20px;
        }
    }

    .spalte-2 {
        flex: 0 1 40%;

        .flex {
            flex-wrap: wrap;
        }

        a {
            color: $blau;
        }

        b, strong {
            font-weight: 700;
        }
    }

    .headerbild {
        // grid-column: 0 / span 2;
        grid-column-start: 0;
        grid-column-end: span 2;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .artikel-col {
        display: grid;
        gap: 34px;
        overflow: hidden;
    }

    .list_se {
        display: grid;
        grid-template-columns: 236px;
        margin: 0;
        padding: 0;
        gap: 16px;
    }

    .content {
        grid-column: 2;
        grid-row: 2;

        &.box {
            margin: 0;
        }
    }

    .berater {
        grid-column-end: span 1;
        grid-row-end: span 2;
        background: #fff;
        position: relative;

        p {
            max-height: 35px;
            overflow: hidden;
            padding: 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    .checkbox {
        height: 15px;
        width: 55px;
        position: absolute;
        right: 14px;
        bottom: 18px;
        z-index: 1;
        cursor: pointer;

        &.active {
            + .content > p {
                max-height: unset;
            }
        }
    }

    figure {
        height: 204px !important;
        background: #fff !important;
    }

    .mehr {
        flex-direction: unset;
        gap: 8px;
        color: $blau;
        height: auto;
        align-items: center;
    }

    .arrow {
        border: solid black;
        border-width: 0 2px 2px 0;
        display: inline-block;
        width: 7px;
        height: 7px;
        border-color: $blau;
    }

    .up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

.hutformen {
    display: flex;
    gap: 16px;

    .flex {
        display: flex;
        gap: 10px;
    }

    .spalte-1 {
        flex: 0 1 calc(60% - 16px);

        img {
            max-width: 100%;
            height: 200px;
        }

        a {
            color: #fff;
            background: #2f80cb;
            padding: 10px 30px;
            border-radius: 5px;
            font-size: 14px;
            display: block;
            max-width: fit-content;
            margin-top: 20px;
        }
    }

    .spalte-2 {
        flex: 0 1 40%;

        .flex {
            flex-wrap: wrap;
        }

        a {
            color: $blau;
        }
    }

    .content,
    strong {
        font-size: 14px;
    }

    strong {
        display: inline;
        font-weight: 700;
    }
}

#shopMitte .service ul.se li.artikel_1 figure {
    height: 204px;
}

.gewinnspiel {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;

    img {
        border-radius: 5px;
        box-shadow: $schattenU;
        align-self: flex-start;
        max-width: 488px;
    }

    h1 {
        font-size: 28px;
        font-weight: 700;
        flex: 1 0 100%;
        color: #737982;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 10px 0;
    }

    ul,
    ol {
        padding: 0 0 0 15px;
    }

    p,
    ul,
    ol {
        margin: 0 0 10px 0;
        line-height: 140%;
        font-size: 16px;
    }

    ol li {
        list-style-type: decimal;
        font-size: 16px;
    }

    .box {
        flex: 1;
        margin: 0;
        min-width: 400px;
    }

    details {
        padding: 20px;
        background-color: #fff;
        flex: 1 1 100%;
        box-shadow: $schattenU;
        border-radius: 5px;
    }

    details > summary {
        font-size: 18px;
        margin: 10px 0;
        cursor: pointer;
        color: $blau;
    }

    details > summary:before {
        content: "+";
        background: $blau;
        padding: 5px 10px;
        margin: 0 10px 0 0;
        border-radius: 60px;
        color: #fff;
        min-width: 10px;
        display: inline-block;
        text-align: center;
    }

    details[open] > summary:before {
        content: "-";
    }

    details summary::-webkit-details-marker,
    details summary::marker {
        display: none;
        content: "";
    }

    summary + div {
        padding: 20px;
    }
}

.versand-container {
    display: flex;
    gap: 20px;

    strong {
        font-weight: bold !important;
    }

    .neutral {
        max-width: 80px;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        flex: 1 0 150px;
    }
}

@media (max-width: 1179px) {
    .gewinnspiel {
        img {
            max-width: 100%;
            height: auto;
        }
    }
}

@media (max-width: 1404px) {
    .low-articels:has(.artikel-col ul:nth-last-child(n + 4), .artikel-col ul:nth-last-child(n + 4) ~ .artikel-col ul) {
        grid-template-columns: 740px;

        .content,
        .artikel-col {
            grid-column-start: 1;
        }

        .content {
            grid-row-start: 3;
        }
    }
}

@media (min-width: 761px) {
    .size_info {
        .groessen-hoover-table {
            overflow: auto !important;
        }

        .td-hover {
            position: relative;
            cursor: pointer;
            background: $blau !important;
            color: #fff;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            &.groesse-65 {
                border-radius: 5px;
            }
        }

        .kategorie {
            position: absolute;
            background: $blau;
            top: -150px;
            left: 0;
            padding: 5px;
            border-radius: 5px;
            border-bottom-left-radius: 0;
            z-index: 99999;

            .item {
                display: flex;
                align-items: center;
                color: #fff;
                padding: 5px 50px 5px 0;
                text-align: left;
                background: url(#{$cdnPfad}/b/picture-navigation-r-white.svg) no-repeat 95% 50%;
                background-size: 8px;
                cursor: pointer;

                &.muetzen > img {
                    height: 30px;
                }

                > img {
                    width: 40px;
                    padding-right: 10px;
                }
            }
        }
    }
}

@media screen\0 {
    #service_mitte.artikel-rueckversand {
        > .abschnitt {
            display: block;

            .img {
                flex: 0 0 auto;
                margin: 0 auto;
            }
        }
    }
}

//landingpage_for_ads
main.hut {
    max-width: 1075px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;

    &.form .wrapper {
        background: transparent;
        padding: 0 0 30px 0;

        label {
            min-width: 236px;
        }

        a {
            position: relative;
            border-radius: 5px;
            margin: 8px;

            img {
                margin-bottom: -3px;
                max-width: 200px;
                max-height: 220px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
            }

            span {
                display: block;
                text-align: center;
                background: #fff;
                padding: 20px 3px;

                // position: absolute;
                bottom: 0;
                width: 100%;
                box-sizing: border-box;
                color: $blau;
                font-size: 1.7rem;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
            }
        }

        .damen,
        .herren {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
        }
    }

    h2 {
        font-size: 1.4rem;
        margin: 20px 15px 15px 25px;
    }

    section {
        label {
            font-size: 18px;
            color: $blau;
            border: 1px solid $blau;
            border-radius: 5px;
            min-width: 8vw;
            box-sizing: border-box;
            text-align: left;
            padding: 10px 5px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            height: fit-content;

            &:hover {
                color: $schriftfarbe;
            }

            &.weiter {
                border-color: #ddd;
                color: #ddd;
            }
        }

        &.groesse {
            justify-content: space-between;
            gap: initial !important;

            label {
                min-width: 90px;
            }
        }

        &.color {
            justify-content: space-between;
            gap: initial !important;

            label {
                min-width: 120px;
            }
        }

        &.gender {
            justify-content: center;

            label {
                width: 6vw;
                min-width: 175px;
            }
        }

        &.form {
            justify-content: center;

            label {
                width: 6vw;
                min-width: 125px;
            }
        }
    }

    .wrapper {
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;
        width: 100%;

        form {
            text-align: center;
            width: 100%;

            > label {
                margin-right: -4px;

                &.tab2 {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &.tab5 {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            > input:checked ~ label {
                background: #ededed !important;
                color: $agrau !important;
            }

            label.bottom {
                height: auto;
            }

            label.start {
                pointer-events: all;
            }
        }

        #tab1:checked {
            + label {
                pointer-events: none;
            }
        }

        #tab1 + label {
            display: none !important;
        }

        .tab {
            //pointer-events: none;

            &:checked + .tab {
                pointer-events: auto;
            }
        }

        .weiter,
        .zurueck {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            background: #fff;
            padding: 8px 20px;
            min-width: unset;
            width: 100px;
            pointer-events: none;

            &:hover {
                color: #fff;
            }
        }

        .zurueck {
            pointer-events: auto;
        }

        section {
            display: none;
            width: 100%;
            position: relative;

            .choice {
                display: flex;

                &.selected {
                    background: $blau;
                    color: #fff;
                }
            }

            .hutgroesse {
                position: absolute;
                right: 0;
                top: 20px;
            }

            .mehrfachauswahl {
                flex: 1 1 100%;
            }

            label.zurueck {
                left: 30px;

                &:hover {
                    color: #d9d9d9;
                }
            }

            label.weiter {
                right: 30px;

                &:hover {
                    color: #d9d9d9;
                }
            }
        }

        input {
            display: none;
        }

        form > label {
            display: none;
            padding: 15px 25px;
            font-weight: 100;
            text-align: center;
            color: #fff !important;
            background: $tuerkis;
            min-width: 4.5vw;
            box-sizing: content-box;
            font-size: 1.4rem;
            pointer-events: none;
        }

        label:hover {
            color: $schriftfarbe;
            cursor: pointer;
        }

        form input:checked + label {
            color: #fff !important;
            background: $blau !important;
        }

        input:checked ~ .weiter {
            background: $blau;
            color: #fff;
            pointer-events: all;
            border: none;
        }

        #tab1:checked ~ #content1,
        #tab2:checked ~ #content2,
        #tab3:checked ~ #content3,
        #tab4:checked ~ #content4,
        #tab5:checked ~ #content5 {
            display: flex;
            flex-wrap: wrap;
            gap: 0 20px;
            height: calc(100% - 70px);
            align-items: center;
            margin: 20px 0;
            box-sizing: border-box;
            padding: 0 30px 60px 30px;
        }

        section#content1 {
            flex-direction: row;
            justify-content: center;
            margin-top: 0 !important;

            .headline {
                font-size: 24px;
                font-weight: 900;
                color: #4b5265;
                margin: -20px 0 10px;
            }

            p.text {
                font-size: 16px;
                margin: 0 0 10px;

                strong,
                b {
                    font-size: 16px;
                }
            }

            .hutfinder-gesichter {
                flex: 1 1 100%;

                img {
                    max-height: 120px;
                }
            }

            label {
                background-color: $blau;
                color: #fff;
                font-size: 16px;
                padding: 10px 30px;

                strong {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 900;
                }
            }
        }
    }
}

@media (max-width: 1404px) {
    .sichere-zahlungsarten {
        .zahlungen {
            > a {
                flex: 0 1 calc(33.33% - 16px);
            }
        }
    }

    .hutformen {
        flex-direction: column;
    }
}

@media (max-width: 1179px) {
    .gesichtsformen {
        .row {
            flex-direction: column;
        }

        .bilder {
            margin: 0 0 20px 0;
        }
    }
}

@media (max-width: 760px) {
    .versand-container {
        flex-direction: column;

        .right {
            align-items: start;
        }
    }

    li.artikel_2 + li.berater {
        grid-row-end: span 6;
    }

    .low-articels.low-articels:has(.artikel-col ul:nth-last-child(n + 4), .artikel-col ul:nth-last-child(n + 4) ~ .artikel-col ul) .artikel-col {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
    }

    .low-articels {
        display: flex;
        flex-direction: column;

        figure {
            height: 204px !important;
            background: #fff !important;
        }

        .list_se {
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: minmax(0, 112px);

            div {
                padding: 0;
            }

            .se_farben {
                height: 27px;
            }
        }

        .artikel-col {
            grid-column: 1 / span 2;
            display: flex;
            flex-wrap: wrap;
        }

        .content {
            grid-row: 4;

            h1 {
                font-weight: 700;
            }

            p,
            b,
            strong,
            a,
            h1,
            h2,
            h3,
            li {
                font-size: 26px;
            }
        }

        .berater {
            grid-row-end: span 4;

            .mehr {
                display: none !important;
            }
        }
    }

    .serienartikel {
        .box,
        .einleitung,
        button,
        .img-wrapper .text,
        .vorteile .headline,
        .vorteile > ul > li {
            font-size: 24px !important;
        }

        .grid {
            grid-template-columns: repeat(2, calc(50% - 8px));
            grid-template-rows: repeat(3, auto);

            .img-wrapper {
                grid-column: 1/3;

                > img {
                    max-width: -webkit-fill-available;
                }
            }

            .text {
                grid-column: 1/3;
            }

            .vorteile {
                grid-column: 1/3;
                grid-row: 4/4;
            }
        }

        .list_se {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            grid-column: 1/3;
            width: 100% !important;

            > li {
                flex: 0 1 calc(50% - 8px);
            }

            .artikel_1,
            .artikel_2 {
                grid-row-end: span 6;
                height: 497px !important;
            }
        }
    }

    .hutformen {
        flex-direction: column;

        .content,
        strong,
        .spalte-1 a,
        .spalte-2 a {
            font-size: $mobile-font-size;
        }
    }

    .hutfinder {
        min-height: 520px;
    }

    .gesichtsformen {
        margin: 0 10px;

        .banner {
            max-width: calc(100% - 15px);

            .content {
                > h1 {
                    font-size: 30px;
                }

                > div {
                    font-size: 26px;
                }
            }
        }

        h1 {
            font-size: 34px;
        }

        p {
            font-size: 26px;
            margin: 0 0 30px 0;
        }

        .startimg {
            text-align: center;
        }

        .row {
            h2 {
                font-size: 30px;
            }

            p {
                font-size: 26px;
            }

            strong {
                font-size: 26px;
            }

            ul {
                li {
                    font-size: 26px;
                }
            }
        }

        .bilder {
            .items {
                &:first-child {
                }

                img {
                    &.model {
                    }
                }

                > div {
                    > span {
                        font-size: 26px;
                    }

                    a {
                        padding: 15px 20px;
                        font-size: 22px;
                    }
                }
            }
        }
    }

    .sichere-zahlungsarten {
        .zahlungen {
            > a {
                flex: 0 1 calc(50% - 16px);
                padding: 40px;
            }
        }

        .texte {
            display: flex;
            flex-wrap: wrap;

            > .box {
                flex: 0 1 calc(100%);
                margin: 16px 0px 0 0;

                strong {
                    font-size: 26px;
                }
            }
        }
    }

    main.hut .wrapper section label.zurueck {
        font-size: 22px;
    }
}
