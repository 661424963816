@media (max-width: 950px) {
    .sucheGross nav {
        width: auto;
    }

    .sucheGross nav > div {
        float: none;
        width: 688px;
        margin: 0 auto 20px auto;
    }
}

@media (min-width: 761px) and (max-width: 1179px) {
    #aa_pic_show nav a {
        height: 68px !important;
        width: 68px !important;
    }
}

@media (max-width: 1179px) {
    .fagrWrapper:not(.alleFA) #mehr_groessen {
        display: block;
    }

    .fagrWrapper:not(.alleFA) {
        margin-bottom: 32px;
    }

    .shop_tab_mitte #service_wrapper nav:before {
        visibility: visible;
    }

    .shop_tab_mitte #service_wrapper nav#men_9:before {
        visibility: hidden;
        display: block;
    }

    .shop_tab_mitte #service_wrapper nav#men_9:hover {
        position: relative;
        background: none;
        padding: 0;
        box-shadow: none;
        right: 0;
    }

    .shop_tab_mitte #service_wrapper nav#men_9 {
        width: 98%;
    }

    .shop_tab_mitte #service_wrapper nav#men_9 ul {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .shop_tab_mitte #service_wrapper nav#men_9 ul li {
        min-width: 135px;
        text-align: center;
        max-width: 345px;
    }

    #service_wrapper nav#men_9 li a {
        background-image: none;
    }

    .shop_tab_mitte #service_wrapper nav {
        visibility: hidden;
        height: 0;
    }

    .shop_tab_mitte #service_wrapper nav#men_9 {
        visibility: visible;
        height: auto;
    }

    .shop_tab_mitte #service_wrapper nav:hover:before {
        visibility: visible;
        background: rgb(255, 255, 255) url(#{$cdnPfad}/ico-menu-act.svg) 275px 17px no-repeat;
        background-size: 25px;
        right: 0;
        top: 0;
        padding-top: 17px;
        border-top: 3px solid #bfbfbf;
        padding-left: 190px;
    }

    button.such-button {
        padding: 8px 55px;
        font-size: 20px;
        top: 86px;
    }

    .temp2 #service_mitte,
    .temp2 #service_rechts {
        width: 740px;
        margin: 20px 0 0 0;
    }

    .temp2 #service_rechts {
        float: left;
    }

    .jobs span:first-child {
        width: 65%;
    }

    #suche_topMarken a,
    #suche_topKategorien a {
        float: left;
        display: block;
        width: 50%;
        margin-bottom: 40px;
        height: 50px;
    }
}

@media (max-width: 1100px) {
    #cs_layer {
        width: 520px;
    }

    #cs_body [data-bn] {
        margin-right: 0;
        width: 100%;
        float: none;
        height: 130px;
    }

    #cs_body [data-bn] figure {
        float: left;
        width: 98px;
        height: 100%;
        margin-left: 16px;
    }

    #cs_body [data-bn] figure img {
        max-height: 98px;
        max-width: 98px;
        margin-top: 0;
    }

    #aa_detail #aa_cross .se_beobachten {
        width: 55px;
        height: 35px;
        background-size: 35px 25px;
    }

    #aa_detail #aa_cross .se_beobachten:after {
        width: 27px;
        height: 20px;
        background-size: 80%;
        z-index: 1;
        position: relative;
    }

    #cs_body [data-bn] .button {
        font-size: 18px;
        text-align: center;
        width: 108px;
        bottom: 0;
        left: auto;
        right: 0;
        box-sizing: border-box;
        height: 130px;
        border-radius: 0;
        line-height: 22px;
        padding-top: 43px;
    }

    #cs_body .se_unterteil {
        float: left;
        text-align: left;
    }

    #cs_body [data-bn] .se_name {
        margin-top: 16px;
    }

    #cs_body [data-bn] select {
        top: auto;
        width: 234px;
        left: initial;
    }
}

@media (max-width: 1635px) {
    #service_mitte {
        width: 488px;
    }

    #service_komplett_neben_menue {
        width: 740px;
    }
}

@media (max-width: 1162px) {
    #service_mitte {
        width: 724px;
        margin: 30px 0 0 16px;
    }

    #service_wrapper {
        width: 740px;
    }

    #service_komplett_neben_menue {
        width: 488px;
    }

    #service_mitte {
        margin: 30px 0 0 0;
    }

    #service_wrapper {
        width: initial;
    }

    #service_komplett_neben_menue {
        width: 740px;
    }
}

@media (max-width: 970px) {
    #service_wrapper.size_info {
        width: 97%;
        padding: 10px;
    }
}

@media (max-width: 1420px) {
    #trustbadge {
        left: 0;
    }

    #shopLayer[data-pp="gloss"] .shop_tab_mitte > div:nth-child(2) {
        margin-bottom: 340px !important;
    }
}

@media (max-width: 1024px) {
    #shopFooter > nav .oben {
        width: initial;
    }

    #shopFooter > nav .oben ul:nth-child(2) {
        padding-right: 8px;
        border-right: 1px solid #e1e1e1;
    }

    #shopLayer[data-pp="gloss"] .shop_tab_mitte > div:nth-child(2) {
        margin-bottom: 470px !important;
    }
}

@media (max-width: 1180px) {
    #aa_person {
        min-height: 126px;
    }

    #aa_person div {
        margin-left: 142px;
    }

    #aa_person img {
        position: absolute;
        top: 50%;

        @include browserPrefixes(transform, translate(0, -50%), webkit);
    }

    #aa_pic_show nav a.b_360 span {
        display: none;
    }

    .ges_preis_in_wk,
    .preis_in_wk,
    .wk_counter input {
        font-size: 16px;
    }

    #wk_gutscheincode {
        width: 450px;
        padding: 0 10px 20px 10px;
    }
}

@media (min-width: 1180px) {
    .sucheGross,
    #FooterJumperBanner {
        width: 1008px;
    }

    #aa_pic_show nav a {
        height: 110px;
        width: 110px;
    }

    #aa_pic_show nav a.b_360 {
        background-size: 40px 40px;
        padding-top: 80px;
    }

    #aa_pic_show nav a.yt-video {
        background-size: 47px 47px;
        padding-top: 80px;
    }

    .startseite .se.list_se.col-1 {
        width: 504px;
    }
}

@media (max-width: 1404px) {
    .wk_counter a.wk_minus {
        top: initial;
        bottom: 0;
    }

    .wk_counter input,
    .wk_counter .anzahl_in_wk {
        left: 0;
        width: 100%;
    }

    .name_in_wk div {
        display: none;
    }

    #aa_mitte2 .se li:last-child {
        margin-left: 0;
    }
}

@media (min-width: 1405px) {
    .se.list_se,
    .sucheGross,
    #FooterJumperBanner {
        width: 1244px;
    }

    #aa_mitte,
    #aa_mitte2,
    #aa_pic_show,
    #aa_mitte2 .se.list_se {
        width: 740px;
    }

    #aa_mitte2 .se {
        width: 740px;
    }

    #aa_pic_show figure {
        height: 730px;
        width: 740px;
    }

    #aa_mitte .slide-container .pic-slide {
        img,
        iframe {
            width: 740px;
        }
        iframe {
            height: 730px;
        }
        video {
            width: 742px;
        }
    }

    #aa_video iframe {
        height: 494px;
    }

    #basket-wrapper {
        width: 236px;
    }

    .top_article ul {
        max-height: 685px;
    }

    .startseite .se.list_se.col-1 {
        width: 756px;
    }
}

@media (min-width: 1654px) {
    #shopMain {
        width: 1528px;
    }

    .se.list_se,
    .sucheGross,
    #FooterJumperBanner {
        width: 1496px;
    }

    #basket-wrapper {
        width: 488px;
    }

    .startseite .se.list_se.col-1 {
        width: 1008px;
    }

    #inWKsenden {
        font-size: 22px;
    }
}

@media (min-width: 1900px) {
    #shopMain {
        width: 1780px;
    }

    .se.list_se,
    .sucheGross {
        width: 1748px;
    }

    #aa_links {
        width: 488px;
    }

    #aa_person {
        min-height: 126px;
    }

    #aa_person div {
        margin-left: 142px;
    }

    #aa_person img {
        position: absolute;
        top: 50%;

        @include browserPrefixes(transform, translate(0, -50%), webkit);
    }

    #aa_mitte,
    #aa_mitt2,
    #aa_pic_show {
        width: 740px;
    }
}

@media (max-width: 940px) {
    .kategorie-banner {
        flex-wrap: wrap;
    }

    .kategorie-banner a {
        margin: 0 8px 8px 8px;
    }

    .kategorie-banner a span.button {
        bottom: 3vw;
        font-size: 3vw;
        min-height: 40px;
        min-width: 360px;
    }
}

@media (max-width: 760px) {
    .zk_wk_summe .b span,
    .guthaben span {
        width: auto;
        white-space: nowrap;
    }

    .wk .groe_txt_st3,
    .wk_zk_div .groe_txt_st3 {
        white-space: unset;
    }

    .se > li.se_banner.sb h1 {
        font-size: 38px;
        margin: 20px 0 40px 0;
        line-height: 140%;
    }

    #aa_mitte .masse-auf-bildern tspan {
        font-size: 45px;
    }

    #html_lagerinfo {
        font-size: 30px;
    }

    .kv-banner.big .bottom .beschreibung,
    .kv-banner.small .bottom .beschreibung {
        color: $schriftfarbe;
        text-wrap: balance;
        font-size: 20px;
    }

    div.shop_tab_mitte #service_wrapper nav#men_9:hover {
        position: relative !important;
        top: unset;
    }

    div.shop_tab_mitte #service_wrapper nav#men_9:hover:before {
        margin-top: 23px;
    }

    html,
    body {
        scroll-padding-top: 10px;
    }

    .lexikon {
        h1 {
            font-weight: bold;
            font-size: 40px;

            & + p {
                font-size: 26px;
            }
        }

        #gloss_nav ul {
            grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        }

        .gloss_alph {
            font-size: 40px;

            div {
                font-size: 30px;
            }
        }
    }

    #aa_pic_show nav a.b_360 {
        height: 110px;
        width: 110px;
    }

    #shopFooter > nav .oben ul {
        flex: 1 0 calc(50% - 20px) !important;
        margin: 0 10px !important;
    }

    #sitemap .ag,
    #sitemap .zk,
    #sitemap .her {
        div {
            margin: 8px 0;
        }
    }

    a.versandart span.versand-preis {
        padding-left: 0;
        margin-right: 0;
    }

    #tipDiv * {
        line-height: 32px;
        font-size: 26px;
    }

    #service_mitte {
        width: 100% !important;
    }

    .se_banner .tab-wrapper label {
        font-size: $mobile-font-size;
    }

    #FooterJumperBanner .fb {
        > h3,
        > h4 {
            font-size: 30px;
            line-height: 36px;
        }

        p,
        p b,
        a {
            font-size: $mobile-font-size;
        }
    }

    .list_se #bannerMehr,
    .list_se #start-bannerMehr {
        font-size: $mobile-font-size;
        padding: 15px 30px;
    }

    .rating {
        font-size: 25px;
        // padding: 17px !important; Was zur Hölle?
    }

    .separate-rating .rating:before {
        font-size: 26px;
    }

    .separate-rating > b,
    .rating-note {
        font-size: 24px;
    }

    div.aa_rating_snippet {
        .separate-rating {
            padding: 40px 0 20px 0;
        }
    }

    .separate-rating > b {
        padding-top: 2px;
    }

    #aa_links.anghangen {
        width: 710px;
        margin-top: 16px;
    }

    #aa_feat a,
    #aa_feat span {
        font-size: 30px;
    }

    .it_features_ul li {
        padding: 5px 0;
    }

    .it_features_ul {
        padding-left: 32px;
    }

    #aa_detail .title,
    #aa_detail #aa_mitte .title,
    #aa_detail #aa_mitte2 .title,
    #aa_detail #aa_links .title {
        font-size: 30px;
        line-height: 40px;
    }

    span.error {
        font-size: 22px;
    }

    div.sale-banner {
        height: 600px;
        background: $rot;
    }

    p.sale-name {
        height: 600px;
    }

    .fagrWrapper:not(.alleFA) #mehr_farben,
    .fagrWrapper:not(.alleFA) #mehr_groessen {
        display: none;
    }

    .desktop {
        display: none;
    }

    .kategorie-banner-all {
        padding-top: 0px;
        margin-top: 0px;
    }

    h1.kategorie-banner-headline {
        font-size: 3.6rem;
        margin: 20px 0 -50px 8px;
    }

    .zahlart span.box[data-za] {
        background-size: 300px;
    }

    .abschluss .order-info {
        display: block;
        width: 705px;
    }

    .abschluss .order-info > div {
        margin: 28px 8px;
    }

    .abschluss .order-info div > p {
        font-size: 40px;
    }

    .abschluss .order-info div span.box {
        font-size: 34px;
        font-weight: 400;
        line-height: 140%;
        height: auto;
        min-height: 150px;
        background-size: 50%;
    }

    .abschluss .order-info span.box a.edit {
        width: 45px;
        height: 45px;
    }

    .abschluss .order-info div.versandart span.box a div {
        font-size: 22px;
        line-height: 130%;
    }

    .order-info span.box a span {
        font-size: 22px;
    }

    .versandart span.box div.teil span.noteil,
    .versandart span.box div.teil label,
    .abschluss .order-info .teil label span {
        font-size: 24px;
    }

    .versandart span.box div.teil label:before {
        top: 33px;
    }

    span.box img {
        max-height: 100%;
    }

    .abschluss .order-info div.versandart span.box a:before,
    .versandart span.box div.teil label:before {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
    }

    .miniSlNav {
        top: 360px;
        opacity: 0.8;
    }

    ul.se .artikel_1 > div:not(.modellBild) figure {
        margin: 0 0 19px;
    }

    .lang + .miniSlNav,
    .lang + .miniSlNav + .miniSlNav {
        top: 609px;
    }

    #shopLayer[data-dhe="sitemap"] div.tab_k {
        font-size: 28px;
    }

    #sitemap .her {
        padding-left: 20px;
    }

    #sitemap span {
        font-size: 30px;
        line-height: 30px;
        padding: 20px;
        min-width: 570px;
        margin: 5px 0;
    }

    #sitemap .title {
        font-size: 26px;
        line-height: 26px;
        width: fit-content;
    }

    #sitemap tr td img {
        display: none;
    }

    tr td[width="5%"] {
        width: 10px;
        border-radius: 15px;
    }

    tr td[width="95%"] span {
        background: $agrau !important;
        width: 553px;
        min-width: 0 !important;
    }

    nav#bread.nobanner {
        margin: 40px 0;
    }

    li.se_banner.vorteil.gr_1x1 {
        height: 475px !important;
    }

    .aa_rating_snippet .rat_stars * {
        background: url(#{$cdnPfad}/star-full-b.svg) no-repeat 50% 50% / 22px;
        width: 22px;
        height: 28px;
    }

    .aa_rating_snippet img.aa_rating_foto {
        height: 200px;
        margin: 0;
    }

    .aa_rating_snippet .rat_product,
    .aa_rating_snippet .rat_image,
    .aa_rating_snippet .rat_krit {
        margin: 0;
    }

    .aa_rating_snippet .separate-rating {
        margin: 0;
        border-bottom: 1px solid #eee;
        padding: 20px 0;
    }

    .aa_rating_snippet .rating-kommentar,
    .aa_rating_snippet .rating-properties *,
    .aa_rating_snippet .rat_beste_hilfreiche *,
    .aa_rating_snippet .rat_beste_kritische *,
    .aa_rating_snippet .rat_2besten,
    .aa_rating_snippet .rat_kopf_innen td span,
    .aa_rating_snippet .rat_kopf_innen td,
    .aa_rating_snippet .aa_rating_artikel,
    .aa_rating_snippet .rat_note td,
    .separate-rating .rating-kommentar {
        font-size: $mobile-font-size;
    }

    .separate-rating .rating-kommentar {
        line-height: 32px;
    }

    .aa_rating_snippet .separate-rating {
        font-size: $mobile-font-size;

        .rating-text,
        b {
            font-size: $mobile-font-size;
        }
    }

    .aa_rating_snippet .rat_kopf_innen {
        justify-content: flex-start;
    }

    .aa_rating_snippet .rat_product {
        flex: 0 1 auto;
        margin: 0 30px 30px 0;
    }

    .aa_rating_snippet .rat_krit {
        flex: 1 0 auto;
        margin: 0 0 30px 0;
    }

    .aa_rating_snippet .rat_image {
        flex: 1 0 100%;
    }

    .gr_1x1 div span,
    .gr_1x2 div span,
    .gr_2x2 div span {
        font-size: 22px;
    }

    li.vorteil.gr_1x1 div {
        height: 55px;
    }

    .vorteil figure {
        width: 100%;
        height: 50%;
    }

    #aa_feat > a:before {
        font-size: 30px;
    }

    .it_features_ul {
        padding-left: 32px;
    }

    .it_features_ul li {
        padding: 5px 0;
    }

    #aa_feat a {
        padding: 5px 0;
    }

    #cs_layer .se_name div {
        text-align: left;
    }

    #cs_body [data-bn] .se_name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 246px;
    }

    .name_in_wk {
        overflow: hidden;
    }

    span.name_in_wk div {
        display: block;
    }

    .name_in_wk div {
        display: block;
    }

    #cs_body .se_unterteil .se_preis {
        font-size: 22px;
    }

    .login_c input[type="radio"] + label:before {
        left: 40px;
        top: 40%;
    }

    .se_farben.slider .lief_gr_farbe1_k {
        top: 7px;
    }

    #mehr_farben {
        height: 70px;
        line-height: 70px;
        font-size: 26px;
    }

    #mehr_farben:after {
        width: 26px;
        height: 26px;
        background-size: 14px;
    }

    div.newsletter-reg h2 {
        font-size: 32px;
    }

    div.newsletter-reg label.field_label {
        font-size: 18px;
    }

    div.newsletter-reg #gender_form {
        line-height: 35px;
    }

    div.newsletter-reg div.form .form_button_submit {
        font-size: 18px;
    }

    div.newsletter-reg div.form ul li {
        display: flex;
        align-items: baseline;
    }

    div.newsletter-reg div.form label.field_label {
        width: 30%;
        font-size: 24px;
    }

    div.newsletter-reg div.form #email_form {
        line-height: 24px;
    }

    div.newsletter-reg div.form .form_field {
        width: 70%;
        font-size: 24px;
    }

    div.newsletter-reg li#gender_form {
        display: flex;
        align-items: center;
    }

    div.newsletter-reg div.form #gender_label {
        height: 90px;
    }

    div.newsletter-reg .form_border ul li label {
        font-size: 20px;
        padding-left: 5px;
    }

    .se_banner.vorteil b {
        font-size: 22px;
    }

    .rating-note {
        padding-top: 0;
    }

    #shopMitte .se > li.gr_2x2 {
        max-height: 1100px;
    }

    li.vorteil.gr_1x1 figure {
        background-size: auto;
    }

    .groe_aus_feat {
        font-size: 24px !important;
        margin-left: 0;
    }

    img.wk-img {
        max-width: 100% !important;
    }

    .wk_counter .wkBox,
    .wk_pic .wkBox {
        min-width: 215px;
    }

    span.error,
    span.info {
        font-size: 22px;
    }

    span.miniSlNav.next {
        left: 300px;
    }

    .sucheGross {
        padding: 0 20px;
    }

    .sucheGross .se.list_se {
        margin-left: -8px;
    }

    .se_slider .slides span.headline,
    .herstellervideo div span.headline {
        font-size: 34px;
    }

    .wkBox span.se_neu.se_diff {
        width: 70px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
    }

    .se > li.gr_1-5x2 {
        min-height: 760px !important;
    }

    li.se_banner.gr_1x1 a.button {
        display: block;
        max-width: 220px;
        width: auto;
        padding: 0;
    }

    li.se_banner.gr_1x1 img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    li.se_banner.vorteil.gr_1x1 img {
        height: unset;
    }

    .box.free-Shipping {
        display: none;
    }

    .nomobile {
        display: none;
    }

    [class^="button_za_dk"] {
        margin: 0 auto;
    }

    body main#shopMain .za_dk-div label[class^="ala_"] {
        padding-top: 30px;
        padding-left: 184px;
        padding-right: 134px;
        padding-bottom: 30px;
        text-align: left;
    }

    .se .se_banner figure {
        width: 100%;
        height: 50%;
    }

    .location_headline {
        position: relative;
        height: 53px;
        line-height: 104px;
    }

    .gutschein #aa_name h1 {
        margin-top: 20px;
        width: 100%;
    }

    .gutschein header > div {
        display: none;
    }

    #aa_detail.gutschein {
        display: table;
    }

    .gutschein #aa_name {
        display: table-header-group;
    }

    .gutschein #basket-wrapper {
        position: relative;
        width: 100%;
        display: table-footer-group;
        float: none;
    }

    .gutschein #aa_mitte,
    .gutschein #aa_mitte2 {
        display: table-header-group;
        float: none;
    }

    .gutschein #aa_links.anghangen {
        display: none;
    }

    img.wk_gutscheinmotiv {
        width: 150px;
    }

    .wkBox[data-za*="echnung"] {
        background-size: 20% !important;
    }

    li.se_banner.gr_1x2.herstellervideo {
        height: 326px !important;
        min-height: initial;
    }

    li.se_banner.gr_1-5x3.herstellervideo {
        height: 396px !important;
        min-height: initial;
    }

    #aa_pic_show figure iframe {
        height: 85% !important;
        width: 85%;
        position: absolute;
        top: 7.5%;
        left: 5.5%;
        z-index: 9999;
        transform: scale(1);
    }

    .menLogButtons {
        display: inline !important;
    }

    #shopLayer[data-pp="gloss"] .shop_tab_mitte > div:nth-child(2) {
        margin-bottom: 930px !important;
    }

    button.such-button {
        padding: 8px 35px;
        font-size: 28px;
    }

    strong {
        font-size: inherit;
    }

    span.ges_preis_in_wk span {
        font-size: 18px;
    }

    .se.side h2 {
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .se_slider .slides h2,
    .se_banner h2 {
        font-size: 32px;
        font-weight: 400;
        height: auto;
        line-height: 38px;
    }

    .slides video {
        height: 380px;
        display: none;
    }

    .slides li.ani_gif {
        background: url(#{$cdnPfad}/01_hutshopping-logo-animation-740x396.gif) 0 0 no-repeat;
        height: 380px;
    }

    .video_text {
        margin-top: 381px;
    }

    .se_slider .flex-control-nav {
        margin-top: -185px;
    }

    li.se_banner.gr_1x2.hersteller {
        height: 171px !important;
        min-height: initial;
    }

    .pg_table_wk1 h5 b {
        font-size: 26px;
    }

    .login_o span {
        padding: 3%;
        font-size: 24px;
    }

    .shop_tab_mitte #service_wrapper nav {
        display: block;
        height: auto;
        visibility: visible;
        width: 100%;
        margin-right: 0;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .shop_tab_mitte #service_wrapper nav:before {
        visibility: visible;
        top: 10px;
        right: -20px;

        &:hover {
            top: -80px;
        }
    }

    .shop_tab_mitte #service_wrapper nav:before {
        background: rgb(242, 242, 242) url(#{$cdnPfad}/ico-menu-act.svg) 134px 15px no-repeat;
        background-size: 35px;
        width: 190px;
        font-size: 20px;
    }

    .shop_tab_mitte #service_wrapper {
        nav:hover,
        nav:hover > ul,
        nav:focus-within > ul {
            display: block;
            position: unset;
            padding-top: 0;
            box-shadow: none;
        }

        nav > ul > li {
            margin-right: 6px;
        }

        nav:hover:before {
            top: 30px;
            background: url(#{$cdnPfad}/ico-menu-act.svg) 320px 26px no-repeat;
            background-size: 25px;
            border-top: none;
            opacity: 0;
        }
    }

    #men_9 {
        width: 100% !important;
        margin: 20px 0;
    }

    #shopLayer[data-pp="bue"] #aa_name div {
        display: none;
    }

    #shopLayer[data-pp="bue"] h1 {
        margin-top: 40px;
    }

    #men_9 ul li {
        width: 48%;
        margin-bottom: 20px;
        float: left;
        margin-right: 25px;
    }

    #men_9 ul li:nth-child(odd) {
        margin-right: 0;
    }

    #service_wrapper nav[id^="men_"] li a {
        background-image: none !important;
        text-align: center;
        padding: 17px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
    }

    .men_pers_1 {
        display: none;
    }

    .bestue_headline {
        height: auto;
        font-size: 28px;
        padding: 20px;
        line-height: normal;
    }

    #service_komplett_neben_menue {
        width: 720px;
    }

    #service_wrapper .bestue_headline.active {
        color: #2c97de;
        background-image: url(#{$cdnPfad}/picture-navigation-u_active.svg);
        background-size: 30px auto;
    }

    table.wk_zk.adress_tabelle tr.tabelle_zk_th {
        display: none;
    }

    .bestue_headline {
        background-size: 20px auto;
    }

    table.wk_zk.adress_tabelle tr td:nth-child(even) {
        margin-right: 0 !important;
    }

    #service_wrapper .adress_tabelle {
        margin: 0 0 30px 0;
    }

    .wk_zk_div > table.wk_zk.adress_tabelle tr {
        padding-top: 0 !important;
    }

    .wk_zk_div > .wk_zk tr td.wk_counter,
    .wk_zk_div > .wk_zk tr td.zk_wk_pic.wk_pic,
    .wk_zk_div > .wk_zk tr td.zk_wk_gsum {
        min-width: 0 !important;
    }

    #service_wrapper .adress_tabelle {
        margin: 0;
    }

    .wk_ausfuehrung b {
        font-size: 30px;
    }

    .wkBox span.gespreis_in_wk.ges_preis_in_wk,
    .preis_in_wk {
        font-size: 25px !important;
    }

    #preis div[itemprop="price"] {
        font-weight: bold;
        font-size: 64px;
        color: RGB(47, 128, 203);
        text-align: right;
        height: 64px;
        line-height: 54px;
        margin-bottom: 16px;
        float: right;
    }

    #pp_express_link {
        height: 70px;
        background-size: 40% !important;
        background-repeat: no-repeat;
        margin-right: 0;
        width: 100%;
        background: #eef0f2 url(#{$cdnPfad}/pay_paypal.svg) 50% 50% no-repeat;
        border: 2px solid #989ba0;
        flex: 1 1 48%;
        margin: 0;
    }

    *[data-pp="wk"] {
        #suchf {
            display: none !important;
        }

        .menu-1 {
            display: none !important;
        }

        #shopMain {
            margin-top: 40px !important;
        }

        #k_wk {
            background-image: url(#{$cdnPfad}/shopping-bag-b.svg);
            background-size: auto 76px;
        }
    }

    .wk_benefits_trust div,
    .wk_benefits_trust div b {
        font-size: 26px;
    }

    #shopHeader > nav.mOpen section.unten {
        background-image: url(#{$cdnPfad}/ico-menu-act.svg);
    }

    .sucheGross section {
        display: none;
    }

    #shopHeader > nav section.unten .active .alvl_1,
    #shopHeader > nav section.unten li:hover > .alvl_1 {
        color: white;
    }

    #shopHeader > nav .lvl_1 .hover > div {
        display: block;
    }

    #shopHeader > nav section.unten .alvl_1:nth-child(1),
    #shopHeader > nav section.unten .alvl_1:nth-child(2) {
        border-top: 2px solid #2a73b7;
    }

    #shopHeader > nav ul li.menLogButtons a:before {
        content: $login;
        font-size: 23px;
    }

    #shopHeader > nav ul li.menLogButtons a {
        background: #2a73b7 url(#{$cdnPfad}/account-m.svg) no-repeat 17% 50%;
        background-size: auto 40px;
        text-align: center;
    }

    #shopHeader > nav ul li.menLogButtons.merk a {
        background-image: url(#{$cdnPfad}/watchlist-active-w.svg);
    }

    *[data-online="1"] #shopHeader > nav ul li.menLogButtons a:before {
        content: $mein_account;
    }

    #shopHeader > nav ul li.menLogButtons.merk a:before {
        content: $merkliste;
    }

    *[data-online="1"] #shopHeader > nav ul li.menLogButtons a {
        background-position: 17% 50%;
    }

    #shopHeader > nav.mOpen section.unten > ul {
        text-align: left;
        display: block;
        top: 204px;
        background: #2e80ca;
        position: absolute;
        width: 750px;
        left: -660px;
    }

    #shopHeader > nav.mOpen.subOpen section.unten > ul {
        top: 120px;
    }

    #shopHeader > nav li.hover div {
        top: 0;
    }

    #shopHeader > nav li.hover div section {
        min-width: 750px;
    }

    #shopHeader > nav.mOpen section.oben {
        border-bottom: 2px solid #2a73b7;
        transition: background 300ms ease-in-out;
    }

    @keyframes mobilizedMenue-Silde-Anim-1 {
        0% {
            @include browserPrefixes(transform, translate(100%, 0%), webkit);
        }

        100% {
            @include browserPrefixes(transform, translate(0%, 0%), webkit);
        }
    }

    @keyframes mobilizedMenue-Silde-Anim-2 {
        0% {
            @include browserPrefixes(transform, translate(0%, 0%), webkit);
        }

        100% {
            @include browserPrefixes(transform, translate(100%, 0%), webkit);
        }
    }

    .unten + button.mobilizedMenue {
        position: absolute;
        width: 90px;
        height: 120px;
        right: 0;
        top: 0;
        background: transparent;
        z-index: 1000;
    }

    .mobilizedMenue-MainContaner {
        position: fixed;
        top: 0px;
        left: 0;
        transform: translate(0, 0);
        z-index: 23001;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0;
    }

    .mobilizedMenue-slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $blau;
        box-shadow: -8px 0px 8px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
    }

    .mobilizedMenue-MainContaner.closed {
        transform: translate(100%, 0);
    }

    .vorteil figure {
        background-size: auto 57px;
    }

    .vorteil > div {
        font-size: 23px;
        padding: 0px;
        height: 40%;
    }

    .se_banner > div > h2,
    .se_banner > div > h3 {
        color: $blau;
        font-size: 30px !important;
        line-height: 36px !important;
        font-weight: 400 !important;
    }

    .se > li.se_banner.sb h3,
    .se > li.se_banner.gr_1-5x1.sb h2,
    .se > li.se_banner.gr_1-5x1.sb h1,
    .se > li.se_banner.gr_1x1.sb h2,
    .se > li.se_banner.gr_1x1.sb h1,
    .se > li.se_banner.gr_2x1.sb h2,
    .se > li.se_banner.gr_2x1.sb h1,
    .se > li.se_banner.gr_3x1.sb h2,
    .se > li.se_banner.gr_3x1.sb h1,
    .se > li.se_banner.gr_2-5x1.sb h2,
    .se > li.se_banner.gr_2-5x1.sb h1 {
        font-size: 30px;
        line-height: 36px;
    }

    .se > li.se_banner.sb p {
        font-size: $mobile-font-size;
    }

    .tab_see {
        max-width: 750px;
        margin-left: -11px;
    }

    #filter_anzeigen {
        transform: scale(1.5);
        margin-top: -12px;
        transform-origin: 100% 0%;
        margin-right: -10px;
    }

    .see_alle_dd > ul,
    .see_alle_dd > form > ul {
        width: 344px;
        margin: 0px 0px 22px 19px;
        height: 75px;
        line-height: 75px;
    }

    #dd_sort_top {
        float: right;
        margin-right: 0;
        margin-left: 0;
    }

    .see_alle_dd ul * {
        font-size: 23px;
    }

    .see_alle_dd ul div[class*="_kopf"],
    *[id*="dd_sort"] div[class*="_kopf"] {
        height: 75px;
        line-height: 75px;
        padding-right: 75px;
    }

    .see_alle_dd ul div[class*="_kopf"]:after {
        width: 30px;
        height: 40px;
        margin-top: 21px;
        margin-right: -55px;
    }

    .see_alle_dd ul .dd_list {
        width: 344px;
    }

    .see_multi_submit {
        height: 55px;
        width: 323px;
    }

    .see_alle_dd ul ul a,
    *[id*="dd_sort"] ul a,
    .see_alle_dd ul ul label {
        line-height: 55px;
    }

    .see_li {
        height: 55px;
        line-height: 55px;
        padding-left: 32px !important;
    }

    .see_li label:before {
        margin-left: -1px;
        margin-top: 16px;
        width: 25px;
        height: 25px;
        margin-right: 16px;
    }

    .see_li.active label:before {
        background-size: 20px;
    }

    div.see_multi_loesch_buttons {
        margin-bottom: 22px;
    }

    .see_multi_loesch_buttons .see_multi_loesch {
        font-size: 30px;
        margin: 10px 15px 0 10px;
        padding: 0 0 0 30px;
        background-size: 20px;
    }

    .see_multi_loesch_buttons .see_multi_loesch.see_multi_loesch_alle {
        background-size: 20px !important;
    }

    .see_multi_loesch_buttons form {
        float: left !important;
    }

    .see_multi_loesch_buttons form:last-child {
        float: right !important;
    }

    body main .see_multi_loesch {
        padding-left: 30px;
        font-size: 25px;
        line-height: 46px;
        background-size: 21px;
    }

    #cs_header .button {
        display: block;
        margin: 20px 0 0 0;
        width: 100%;
        font-size: 29px;
        height: 75px;
        line-height: 75px;
    }

    #cs_layer #cs_header h1 {
        font-size: 35px;
    }

    #cs_layer span {
        font-size: 22px;
    }

    #cs_layer #cs_header {
        padding: 30px 22px;
    }

    #aa_fullview_button {
        display: none;
    }

    #aa_pic_show figure {
        height: 700px;
        width: 710px;
        .controls {
            display: none;
        }
        .pic-slide {
            width: 710px !important;
            img,
            iframe {
                width: 710px !important;
                height: 700px !important;
            }
            video {
                width: 710px !important;
            }
        }
    }

    #aa_pic_show nav a {
        width: 100px;
        height: 100px;
        margin: 12px 0 6px 22px;
    }

    #aa_pic_show nav {
        margin-left: -22px;
        margin-bottom: 12px;
    }

    #aa_pic_show nav a img {
        height: 74px;
        width: 74px;
    }

    #aa_pic_show nav a.b_360,
    #aa_pic_show nav a.yt-video {
        padding-top: 66px;
        background-size: 37px 37px;
        font-size: 18px;
        height: 110px;
        width: 110px;
    }

    #b_socs a {
        height: 46px;
        width: 46px;
    }

    #b_socs {
        height: 46px;
        left: 14px;
        bottom: 22px;
    }

    #preis {
        padding: 22px;
    }

    .small #preis div[itemprop="price"] {
        font-size: 60px;
    }

    #preis .mwst,
    #preis .mwst * {
        font-size: 20px;
    }

    .location_headline > span a span {
        color: #2f80cb;
    }

    .location_headline > span:last-child a span {
        color: RGB(115, 121, 130);
    }

    #basket #aa_groe_tab {
        font-size: 30px;
        line-height: 30px;
        height: 30px;
    }

    #basket #aa_groe_tab:before {
        height: 28px;
        width: 40px;
        margin-left: -55px;
    }

    .fagr {
        width: 100px;
        height: 100px;
        padding-top: 14px;
    }

    .fagr img {
        width: 75px;
    }

    .fagr.active {
        border: 4px solid RGB(47, 128, 203);
        padding-top: 10px;
    }

    .fagr[data-gr] div {
        height: 100px;
        width: 100px;
        font-size: 22px;
    }

    .fagr.active[data-gr] div {
        height: 92px;
        width: 92px;
    }

    #inWKsenden {
        height: 100px;
        font-size: 43px;
        line-height: 100px;
    }

    #basket .ad_benefits .vorteil {
        width: 100%;
        height: 222px;
    }

    #basket .vorteil > div {
        padding: 23px;
    }

    figure .controls b {
        width: 63px;
        background-size: 30px auto;
    }

    .separate-rating > b,
    .rating-note {
        font-size: $mobile-font-size;
    }

    #aa_mitte2 .se {
        width: 740px;
    }

    #shopLayer[data-pp="suche"] .location_headline {
        line-height: 7px;
        font-size: 28px;
        margin-left: 13px;
    }

    #shopFooter .unten > figure,
    #shopFooter .unten > ul {
        height: 188px;
    }

    #shopFooter .unten .f_socs a {
        background-size: auto 72px !important;
    }

    #shopFooter .unten .f_socs a[href*="ewsletter"] {
        background-size: auto 55px !important;

        + span {
            font-size: 22px;
        }
    }

    #shopFooter .unten #f_text span,
    #shopFooter .unten #f_text span * {
        font-size: 24px;
        line-height: 32px;
    }

    #shopFooter .unten #f_text {
        padding: 40px 56px 40px 56px;
    }

    #shopFooter > nav .oben ul a {
        font-size: 24px;
    }

    #trustbadge {
        transform: scale(2.09);
        top: 49px;
        left: 285px;
    }

    .nur_nachnahme.a {
        width: 160% !important;
        margin: 10px 0;
        font-size: 20px;
    }

    #shopLayer .aktive_felder#wk_gutscheincode input,
    #wk_gutscheincode button {
        height: 45px;
        line-height: 45px;
    }

    .wk_counter + td,
    .zk_wk_ep {
        display: none !important;
    }

    .zk_wk_ep {
        display: none !important;
    }

    .wk_zk_div > .wk_zk tr td .name_in_wk div {
        display: block;
    }

    .wk_zk_div > .wk_zk tr td .artikelnummer_in_wk {
        margin-top: 0px;
    }

    [class^="groe_txt"] {
        font-size: 26px;
    }

    .name_in_wk {
        font-size: 26px;
        padding-bottom: 0 !important;
    }

    .name_in_wk div {
        display: inline;
    }

    .name_in_wk div,
    .artikelnummer_in_wk,
    .wk_ausfuehrung,
    .wk div[class*="groe_txt"],
    .artikelnummer_in_wk:after {
        font-size: 26px;
        margin: 5px 0;
        white-space: nowrap;
    }

    .wk_ausfuehrung {
        white-space: normal;
    }

    .wk .lager.low {
        font-size: 26px;
    }

    .artikelnummer_in_wk {
        display: none;
    }

    .wk_counter .anzahl_in_wk a {
        font-size: 25px;
        background-size: 26px auto;
    }

    .wk_counter input,
    .wk_counter .anzahl_in_wk {
        font-size: 40px;
        height: 40px;
    }

    .wk_zk_div {
        .wk_counter input,
        .wk_counter .anzahl_in_wk {
            font-size: 36px;
        }
    }

    .ges_preis_in_wk,
    .preis_in_wk {
        font-size: 30px;
        text-align: right;
    }

    #wk_topping h1 {
        font-size: 40px;
    }

    #wk_topping p,
    #wk_topping p i {
        font-size: 26px;
    }

    .bestandsk table.wk,
    .bestandsk table.wk_zk {
        > tbody > tr {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    table.wk,
    table.wk_zk {
        > tbody > tr {
            display: flex;
            box-sizing: border-box;

            .wk_ges_preis_td {
                flex: 1;
                flex-grow: 0;
            }
        }

        .wkBox {
            min-width: 0;
        }
    }

    .wk_gsumme {
        font-size: 40px;
    }

    .wk_rab_zeile {
        font-size: 26px;
    }

    .wk_gsumme span {
        font-size: 26px;
    }

    .button.wk_zk_button {
        height: 125px;
        line-height: 125px;
        font-size: 40px;
        width: 100%;
        margin: 0;
    }

    .spalteReduziert strike i span {
        font-size: 26px;
    }

    tr[data-gutschein]:not([data-gutschein="0"]) .wk_counter,
    tr[data-gutschein]:not([data-gutschein="0"]) > td:last-child {
        display: none;
    }

    #wk_gutscheincode {
        width: 100%;
        height: 71px;
        margin-bottom: 22px;
        margin-top: 22px;
    }

    #shopLayer .aktive_felder#wk_gutscheincode input,
    #wk_gutscheincode button {
        height: 48px;
        line-height: 45px;
    }

    #shopLayer .aktive_felder#wk_gutscheincode input {
        width: 410px;
        margin-top: 49px;
    }

    #wk_gutscheincode button {
        margin-top: 40px;
        font-size: 26px;
    }

    body #shopLayer .aktive_felder#wk_gutscheincode label:before {
        border-bottom-width: 4px;
    }

    body #shopLayer .aktive_felder#wk_gutscheincode label.active:before {
        width: 448px;
    }

    .zk_wk_summe {
        margin-right: 0;
        margin-bottom: 40px;
        margin-top: 20px;
    }

    .tabelle_zk_th {
        display: none;
    }

    .tabelle_zk * {
        font-size: 25px;
        line-height: 140%;
    }

    .tabelle_zk div div {
        margin-right: 30px;
    }

    .wk_zk.adress_tabelle td {
        display: block;
        width: 100%;
    }

    #shopMitte .adress_tabelle .wkBox {
        padding: 20px;
        margin: 0 0 22px 0;
        width: 100%;
        line-height: 45px;
        height: auto;
        background-size: 244px;
        overflow: visible;
        position: relative;
        background-position: 20px;
        min-height: 100px;
    }

    #shopMitte .adress_tabelle .wkBox:before {
        font-size: 40px;
        content: " ";
        display: block;
        margin-top: -10px;
        position: absolute;
        white-space: nowrap;
    }

    #shopMitte .adress_tabelle .wkBox.rech_box:before {
        content: $ihre_rechnungsadresse;
    }

    #shopMitte .adress_tabelle .wkBox.email_box:before {
        content: $ihre_emailadresse;
    }

    #shopMitte .adress_tabelle .wkBox[data-za]:before {
        content: $ihre_zahlungsart;
    }

    #shopMitte .adress_tabelle .wkBox.ala_box:before {
        content: $ihre_lieferanschrift;
    }

    #shopMitte .adress_tabelle {
        width: 710px;
    }

    .adress_tabelle .wkBox a {
        width: 54px;
        height: 54px;
        background-size: 29px;
    }

    .adress_tabelle * {
        font-size: 30px;
    }

    #pp_link {
        float: none;
        margin: 45px 0 0 0;
        width: 710px;
        height: 125px;
        line-height: 125px;
        font-size: 30px;
    }

    .zk_wk_summe .b span,
    .guthaben span {
        font-size: 26px;
        width: 60%;
    }

    .zk_wk_summe .b span:last-child,
    .guthaben span:last-child {
        font-size: 40px;
    }

    .mitte_login.abschluss .button,
    #wk .button {
        width: 100%;
        height: 125px;
        font-size: 43px;
        margin: 20px auto;
        float: none;
        display: block;
        padding: 20px;
    }

    .wkBox[data-za] {
        min-height: 280px;
    }

    .login_o {
        font-size: 20px;
    }

    .div_login > div {
        width: 710px;
        margin-left: 6px;
        border-radius: 9px;
    }

    #shopMain .shop_mitte .mitte_login .login_a input,
    #shopMain .shop_mitte .mitte_login .login_b input {
        width: 677px !important;
        margin-top: 45px;
    }

    #shopLayer .div_login .aktive_felder label:before {
        top: 83px;
        border-bottom-width: 4px !important;
    }

    #shopLayer .div_login .aktive_felder label.active:before {
        width: 677px;
    }

    body #shopLayer .aktive_felder label.active {
        padding-top: 0px;
    }

    .login_a .pass_f {
        top: 150px;
        font-size: 24px;
    }

    .mitte_login .button {
        height: 75px;
        line-height: 75px;
        width: 680px;
        margin-top: 45px;
    }

    .login_a {
        height: auto !important;
    }

    body .login_ueb {
        font-size: 30px;
        display: block;
        text-align: center;
        width: 100%;
        top: -54px;
    }

    #guest_anz_button {
        height: 68px;
        background-size: 50px auto;
    }

    #guest_anz_button:before {
        width: 50px;
        height: 50px;
    }

    .div_login > div.login_c {
        width: 710px;
    }

    body #shopLayer ul.aktive_felder li input {
        width: 677px;
        margin-left: 0;
    }

    #shopMain .shop_mitte .mitte_login .login_c input {
        margin-top: 45px;
    }

    #pp_guestco form > * {
        margin: 0;
    }

    ul.aktive_felder {
        width: 677px;
    }

    body #shopLayer #schnellog.aktive_felder label,
    body #shopLayer #ala_schnell.aktive_felder label {
        margin-top: 0;
        padding-left: 0;
    }

    body #shopLayer #schnellog.aktive_felder li input#schnellog_inpu5_1,
    #shopLayer .div_login .aktive_felder label[for="schnellog_inpu5_1"].active:before {
        width: 395px;
    }

    body #shopLayer #schnellog.aktive_felder li input#schnellog_inpu5_2 {
        margin-left: 470px;
        top: -45px;
        width: 209px;
    }

    #shopLayer .div_login .aktive_felder label[for="schnellog_inpu5_2"] {
        left: 470px;
        width: 209px;
    }

    #shopMain .shop_mitte .mitte_login select {
        padding-top: 10px;
        margin-left: -5px;
        font-size: 28px !important;
        height: 50px !important;
        font-family: Roboto, Arial, sans-serif !important;
        margin-top: 45px !important;
    }

    #schnellLog_feld12 {
        margin-top: 45px;
    }

    .ala_tr td {
        text-align: left;
    }

    .mitte_login #pp_guestco .ala_tr + tr .button {
        margin-left: -1px;
    }

    #schnellog {
        margin-left: 3px !important;
    }

    #schnellog_za_table:before,
    #schnellog_za_table > tbody > tr:nth-child(4) > td:nth-child(2):before {
        content: $ihre_zahlungsart;
        display: block;
        width: 100%;
        text-align: center;
        font-size: 30px;
        color: #a3abb8;
        font-weight: bold;
        margin-bottom: 13px;
    }

    #schnellog_za_table > tbody > tr:nth-child(4) > td:nth-child(2):before {
        margin-top: 30px;
        content: $versandadresse;
    }

    #schnellog_za_table {
        margin-top: 22px !important;
        margin-left: -5px !important;
    }

    #schnellog_za_table > tbody > tr:first-child,
    #schnellog_za_table > tbody > tr:nth-child(2) > td:first-child,
    #schnellog_za_table > tbody > tr:nth-child(4) > td:first-child,
    #schnellog_za_table > tbody > tr:last-child > td:first-child,
    #schnellog_za_table > tbody > tr:nth-child(3) {
        display: none;
    }

    body main#shopMain .za_dk-div label {
        border-width: 4px !important;
        border-radius: 9px !important;
        width: 100%;
        height: auto;
        font-size: 30px;
    }

    body main#shopMain [class^="button_za_dk"] {
        height: 80px;
        display: block;
        float: none;
        width: 100%;
    }

    body main#shopMain [class^="text_za_dk"] {
        font-size: 30px;
        width: 100%;
        text-align: center;
        float: none;
        display: block;
        margin-bottom: 22px;
    }

    body main#shopMain .login_c input[type="radio"] + label:before {
        transform: scale(2);
        transform-origin: 0 0;
    }

    body main#shopMain .za_dk-div label[class^="ala_"]:before {
        transform: scale(2) translate(-50%, -50%);
    }

    .see_multi_submit,
    .see_alle_dd > ul,
    .see_alle_dd > form > ul,
    wk_zk_div,
    .fagr,
    #aa_pic_show,
    #preis,
    #aa_pic_show nav a,
    #basket .vorteil,
    #aa_bewer,
    #aa_h_info > div,
    #aa_text,
    #aa_person,
    #aa_feat,
    #aa_pic_show figure,
    .wkBox,
    #wk_gutscheincode button {
        border-radius: 9px;
    }

    .se.start.side {
        margin-top: 20px;
    }

    .temp2 #service_mitte,
    .temp2 #service_rechts {
        width: 726px;
        margin: 20px 0 0 0;
    }

    #service_wrapper > nav {
        display: none;
    }

    #service_wrapper table[class*="style2"] {
        display: block;
        overflow-x: scroll;
    }

    #service_wrapper table[class*="style2"] td,
    #service_wrapper table[class*="style2"] th {
        vertical-align: middle;
        padding: 9px !important;
        text-align: center !important;
        font-size: 22px !important;
        font-weight: 300 !important;
        min-width: 50px !important;
    }

    #service_mitte table {
        display: block !important;
        width: 100% !important;
        overflow: hidden !important;
        overflow-x: scroll !important;
        box-shadow:
            inset 27px 0px 32px -28px rgba(0, 0, 0, 0.7),
            inset -27px 0px 32px -28px rgba(0, 0, 0, 0.7) !important;
        background: #fff !important;
        margin: 15px auto !important;
        border-radius: 5px !important;
    }

    #service_wrapper table.style2 tr:nth-child(even) td {
        background-color: transparent !important;
    }

    .se.list_se.single {
        margin-top: 0 !important;
    }

    #service_wrapper article p,
    #service_wrapper article li,
    #service_wrapper article a,
    #service_wrapper article strong {
        font-weight: 300;
        font-size: $mobile-font-size;
        line-height: 32px;
    }

    #service_wrapper article .adresse p,
    #service_wrapper article .adresse a,
    #service_wrapper article .adresse p strong {
        font-size: 28px;
        line-height: 30px;
    }

    #service_wrapper article.jobs .adresse {
        width: 100%;
        position: relative;
        background: #297ece;
        height: 100%;
        color: #fff;
        display: block;
        padding: 20px;
        margin-left: -15px;
        margin-bottom: -30px;
    }

    .jobs span:first-child {
        width: 100%;
    }

    #service_wrapper {
        .serviceVideo,
        .serviceImage {
            > div {
                font-size: $mobile-font-size;
                padding: 10px 0;
            }
        }

        p,
        .wrap-pflege ol li,
        .text-red,
        article,
        .newsdat,
        ol li,
        ul li {
            font-size: $mobile-font-size;
        }

        p a {
            font-size: $mobile-font-size;
        }

        .newsdat {
            margin-top: 10px;
        }

        .stoffe li span {
            font-size: 18px;
        }

        h1,
        h2,
        h1 a {
            font-size: 36px;
            margin-top: 15px;
            text-align: left;
            font-weight: 500;
        }

        #aa_name h1 a {
            display: block;
            height: 100%;
            margin-top: 0px !important;
        }

        h2 {
            font-size: 32px !important;
            text-align: left;
        }

        h3 {
            font-size: 30px !important;
        }

        .help-headline {
            font-size: 36px;
        }

        .wrap-pflege {
            margin-top: 0;
        }
    }

    #service_wrapper #aa_name h1 {
        margin-bottom: 0px;
        margin-top: 0px;
    }

    .contact_form .form_left,
    .contact_form .form_right {
        width: 100%;
    }

    body #shopLayer .contact_form .aktive_felder input {
        width: calc(100% - 50px) !important;
    }

    body #shopLayer .aktive_felder label.active:before {
        width: 656px;
    }

    body #shopLayer #service_wrapper .aktive_felder label {
        margin-top: 0px;
    }

    body #shopLayer #service_wrapper .aktive_felder input {
        height: 50px;
    }

    body #shopLayer #service_wrapper .aktive_felder input {
        padding: 40px 16px 0 0;
    }

    body #shopLayer #service_wrapper .aktive_felder label {
        margin: 40px 16px 0 0;
        height: 55px;
    }

    body #shopLayer #service_wrapper .contact_form .aktive_felder textarea {
        width: 93%;
        font-size: 30px;
    }

    body #shopLayer .contact_form .button {
        margin-right: 33px;
        font-size: 32px;
        padding: 20px 70px;
        height: auto;
        width: auto;
    }

    .contact_form select {
        width: calc(100% - 40px) !important;
        height: 50px;
        border-bottom: 3px solid RGB(47, 128, 203);
    }

    body #shopLayer .aktive_felder select {
        font-size: 30px;
    }

    .contact_form #select + label:after {
        content: " ";
        background-image: url("#{$cdnPfad}/sprite.svg");
        background-repeat: no-repeat;
        position: absolute;
        top: -71px;
        right: -15px;
        pointer-events: none;
        width: 56px;
        height: 56px;
        background-size: 400%;
    }

    #service_wrapper h3 {
        font-size: 23px;
    }

    #service_wrapper .no_mobile,
    .startseite .no_mobile {
        display: none;
    }

    #service_wrapper .button {
        width: 100%;
        font-size: 30px;
    }

    #service_wrapper table[class^="style"] {
        display: block;
        overflow-x: scroll;
    }

    #service_wrapper table[class^="style"] td,
    #service_wrapper table[class^="style"] th {
        font-size: 20px;
        vertical-align: middle;
        padding: 10px;
        min-width: 120px;
    }

    .f-mini {
        font-size: 16px;
    }

    #aa_rating.rating:before {
        font-size: 26px;
    }

    img.artikel-detail_img {
        width: 85%;
    }

    .amazonWrapper {
        width: 725px;
        height: 500px;
        position: relative;
    }

    .amazonWrapper #addressBookWidgetDiv,
    .amazonWrapper #walletWidgetDiv {
        width: 50%;
        height: 50%;
        transform: scale(2);
        transform-origin: 0 0;
    }

    .amazonWrapper {
        margin-top: 20px;
    }

    #LoginWithAmazon {
        transform-origin: 0 0;
        clear: both;
        width: 100%;
        text-align: left;
        margin: 40px 0;
        flex: 1 1 48%;
        margin: 0;
    }

    .div_login #LoginWithAmazon {
        text-align: center;
        margin-top: 40px;
        width: 80%;
    }

    #LoginWithAmazon img {
        width: 100%;
        max-width: unset !important;

        @include browserPrefixes(transform-origin, 0 0, webkit);
    }

    #FooterJumperBanner .gr_3.img,
    #FooterJumperBanner .gr_2.img {
        display: none;
    }

    .se.list_se .hutkoffer {
        min-height: 477px !important;
    }

    #shopMitte li.gr_1x2 {
        min-height: 435px;
    }

    .startseite .cat .se {
        max-height: 500px;
        overflow: hidden;
    }

    .startseite .top_article ul.se {
        max-height: 1992px;
        overflow: hidden;
        height: auto !important;
    }

    .service.payment figure {
        background-size: 17% !important;
    }

    .service figure {
        background-size: 20% !important;
    }

    .benefits {
        max-height: 255px;
    }

    .startseite .se {
        margin-top: -10px;
    }

    .top_article ul {
        max-height: none;
    }

    .startseite .m_nav.no_desktop {
        display: flex;
        margin: 0 0 10px 0;
    }

    .startseite .headline-h2 {
        font-size: 40px;
    }

    .se .se_banner.vorteil figure {
        width: 100%;
        height: 50%;
    }

    .se .se_banner.vorteil div div {
        padding: 10px 0;
    }

    .startseite .cat.se_banner {
        min-height: 0;
    }

    .startseite .banner-finder p {
        font-size: 53px;
    }

    .startseite .banner-finder span {
        font-size: 22px;
    }

    .startseite .se_banner img {
        width: 100%;
    }

    .startseite .banner-finder img {
        width: 19%;
    }

    .cat.se_banner.gr_1x2 {
        height: 475px;
        max-height: none;
    }

    .startseite .blog {
        display: none;
    }

    .startseite .service {
        max-height: 630px;
    }

    .service div {
        font-size: 25px;
        padding-top: 15px;
    }
}

@media (max-width: 1653px) {
    .sucheGross.active input {
        width: 70% !important;
    }

    .sucheGross nav > div:nth-of-type(2) {
        flex: 1;
    }

    .name_in_wk {
        // font-size: 14px;
        padding-bottom: 6px;
    }

    .temp2 #service_mitte {
        width: 500px;
        margin: 0 16px 0 0;
    }

    body #shopLayer .aktive_felder label.active:before {
        width: 202px;
    }

    body #shopLayer .contact_form .aktive_felder input {
        width: 202px;
    }

    .contact_form select {
        width: 202px;
    }

    .jobs span:first-child {
        width: 51%;
    }

    .sucheGross .se.list_se.single li:last-child {
        display: none;
    }

    .sucheGross .se.list_se.single li {
        margin: 0 auto !important;
        float: none;
        position: relative;
    }

    #landingpage .temp2 #service_mitte {
        width: 756px;
    }
}

@media (max-width: 1404px) {
    button.such-button {
        padding: 10px 85px;
        font-size: 25px;
        top: 100px;
    }

    #service_wrapper #service_rechts .list_se {
        width: 740px;
    }

    .temp2 #service_mitte,
    .temp2 #service_rechts {
        width: 740px;
        margin: 0;
    }

    .temp2 #service_rechts {
        margin: 20px 0 0 0;
    }

    .serviceVideo iframe {
        height: 295px;
    }

    body #shopLayer .aktive_felder label.active:before {
        width: 311px;
    }

    body #shopLayer .contact_form .aktive_felder input {
        width: 311px;
    }

    .contact_form select {
        width: 311px;
    }

    .suche_marke {
        width: 80px;
    }

    #landingpage .temp2 #service_mitte {
        width: 100%;
    }
}

@media print {
    * {
        -webkit-print-color-adjust: exact;
    }

    html,
    body {
        height: auto !important;
    }

    article {
        box-shadow: none !important;
        border: 2px solid #ccc;
    }

    #shopHeader,
    #shopFooter > nav,
    .f_socs,
    article.trustedshops,
    .order-print {
        display: none !important;
        height: 0 !important;
    }

    section.unten {
        background: transparent !important;
    }

    #shopHeader {
        display: none;
    }

    #shopFooter .unten #f_text span,
    #shopFooter .unten #f_text span a {
        color: #999 !important;
    }

    #shopLayer {
        min-height: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    #trustbadge {
        display: none;
    }

    #shopFooter .unten {
        min-height: 50px;
    }

    #shopFooter {
        position: relative;
    }

    #banner_ct::before {
        content: " ";
        display: block;
        margin: 0 auto 30px auto;
        width: 400px;
        height: 80px;
        background: url(#{$cdnPfad}/hutshopping-logo-ohne.svg) 16px 50% no-repeat;
        visibility: visible;
    }

    #shopFooter .unten #f_text {
        padding: 0 300px;
    }
}

@media (max-width: 820px) {
    div#sitemap {
        width: auto;
    }

    #shopLayer[data-dhe="sitemap"] div.tab_k {
        width: auto;
    }
}

@media (max-width: 772px) and (min-width: 760px) {
    .adress_tabelle .wkBox.rech_box {
        margin-left: 0px;
    }
}

@media (max-width: 826px) {
    #shopMain .shop_mitte .mitte_login .login_c select {
        font-size: 24px !important;
    }
}

@media only screen and (max-width: 768px) {
    figure[data-m-pic="minipic_"] .masse-caps {
        background: url(#{$cdnPfad}/b/masse_caps_icon.svg) no-repeat 0 0;
        width: 70px;
        height: 70px;
        background-size: contain;
        position: absolute;
        z-index: 300;
        bottom: 10px;
        right: 0;
        display: block !important;
        left: 0;
        margin: 0 auto;
        cursor: pointer;
    }

    figure[data-m-pic="minipic_"].show_masse .masse-auf-bildern {
        opacity: 1;
        z-index: 6;
    }

    figure[data-m-pic="minipic_"].hide_masse .masse-auf-bildern {
        z-index: -6 !important;
        opacity: 0 !important;
    }

    #aa_detail .se_beobachten {
        background-size: 45px;
        //padding: 30px;
    }

    #aa_detail #aa_serie .se_beobachten {
        width: 55px;
        height: 35px;
        background-size: 35px 25px;
    }

    #aa_pic_show .se_beobachten.active:after {
        transform: scale(1.8);
    }

    .wrapper-hersteller-menu div.hersteller-menu .lvl_1,
    .hersteller-menu section.general {
        width: 100%;
    }

    .wrapper-hersteller-menu div.hersteller-menu .lvl_1 li a {
        font-size: 20px;
    }

    div.wrapper-hersteller-menu div.hersteller {
        width: 100%;
        height: 120px;
    }

    div.wrapper-hersteller-menu div.hersteller-menu li {
        margin: 0 40px 30px 0;
    }

    div.wrapper-hersteller-menu div.hersteller img {
        width: 100%;
        height: 100px;
    }

    .wrapper-hersteller-menu div.hersteller-menu .lvl_1 {
        width: 100%;
    }

    div.wrapper-hersteller-menu div.hersteller-menu li.head {
        font-size: 35px;
    }
}

@media (min-width: 769px) {
    .wrapper-hersteller-menu div.hersteller-menu nav ul.w-4 {
        width: 50%;
    }

    .hersteller-menu section {
        width: 100%;
        display: flex;
    }
}

@media only screen and (max-width: 1180px) {
    .wrapper-hersteller-menu {
        flex-direction: column;
        margin-top: 0;
    }

    .wrapper-hersteller-menu div.hersteller {
        margin: 20px 0;
        align-self: flex-end;
    }

    .wrapper-hersteller-menu div.hersteller img {
        width: 80px;
    }
}

@media screen and (max-width: 814px) {
    li.vorteil.gr_1x1 div {
        height: 200px;
    }
}

@media (max-width: 1085px) {
    .vote {
        width: 110px;
    }
}

@media (max-width: 955px) {
    #tsbadge4_db8d3657bdbe440c985ae127463eaad4 {
        display: none !important;
    }
}

@media (max-width: 840px) {
    .vote {
        display: none;
    }
}

@media (min-width: 761px) {
    .top_article ul {
        max-height: 1026px;
        height: 1026px !important;
    }

    .startseite .se.list_se.col-1 {
        width: 252px;
        display: block;
    }
}

@media (max-width: 800px) {
    .startseite .se_banner span.button {
        bottom: 10vh;
    }
}

@media only screen and (min-width: 769px) {
    .wrapper-hersteller-menu div.hersteller-menu .lvl_1 {
        padding-right: 20px;
    }
}
